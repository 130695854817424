/* eslint-disable react/prop-types */
import React from "react";
import styles from "./ListEvidence.module.css";
import ProgressBar from "../ShowDataTable/ProgressBar/ProgressBar";

const ListEvidence = ({ showBackButton, currentStep }) => {
  // Example hardcoded extracted data
  const extractedData = [
    {
      title: "Evidence 1",
      files: ["file1.pdf", "file2.docx"],
      lastUpdatedBy: "User A",
      lastUpdatedOn: "2024-12-17",
    },
    {
      title: "Evidence 2",
      files: ["image1.png", "notes.txt"],
      lastUpdatedBy: "User B",
      lastUpdatedOn: "2024-12-16",
    },
  ];

  const headerData = ["Title", "Files", "Last Updated By", "Last Updated On"];

  return (
    <div className={styles.container}>
      {showBackButton ?
        <button className={styles.backButton}><svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 1.42L3.42 6L8 10.59L6.59 12L0.59 6L6.59 1.23266e-07L8 1.42Z" fill="#1232C3" />
        </svg>
          Back</button>
        : null}
      {currentStep && <ProgressBar currentStep={currentStep} />}
      <div className={styles.heading}>List of Evidences</div>
      <button className={styles.attachButton}>
        <i className="bi bi-paperclip"></i> Attach Evidence
      </button>
      <span> Supported files type : .pdf, .doc, .xls</span>
      <table className={styles.table}>
        <thead>
          <tr>
            {headerData.map((header, index) => (
              <th key={index} className={styles.headerCell}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {extractedData.map((data, rowIndex) => (
            <tr key={rowIndex} className={styles.row}>
              <td className={styles.dataCell}>{data.title}</td>
              <td className={styles.dataCell}>
                <div className={styles.fileInfo}>
                  {data.files.map((file, fileIndex) => (
                    <div key={fileIndex} className={styles.fileRow}>
                      <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 0H6C4.9 0 4 0.9 4 2V18C4 19.1 4.9 20 6 20H18C19.1 20 20 19.1 20 18V6L14 0ZM18 18H6V2H13V7H18V18ZM2 4V22H18V24H2C0.9 24 0 23.1 0 22V4H2ZM8 10V12H16V10H8ZM8 14V16H13V14H8Z" fill="#5037E9" />
                      </svg>

                      <a
                        href={`path/to/files/${file}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.fileName}
                      >
                        {file}
                      </a>
                      <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.857143 14.2222C0.857143 14.6937 1.03775 15.1459 1.35925 15.4793C1.68074 15.8127 2.11677 16 2.57143 16H9.42857C9.88323 16 10.3193 15.8127 10.6408 15.4793C10.9622 15.1459 11.1429 14.6937 11.1429 14.2222V3.55556H0.857143V14.2222ZM2.57143 5.33333H9.42857V14.2222H2.57143V5.33333ZM9 0.888889L8.14286 0H3.85714L3 0.888889H0V2.66667H12V0.888889H9Z" fill="#5037E9" />
                      </svg>


                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.7725 3.14373C14.0758 2.84044 14.0758 2.33495 13.7725 2.04722L11.9528 0.227468C11.665 -0.0758228 11.1596 -0.0758228 10.8563 0.227468L9.42536 1.6506L12.3416 4.56687M0 11.0837V14H2.91626L11.5173 5.3912L8.60103 2.47493L0 11.0837Z" fill="#5037E9" />
                      </svg>
                    </div>
                  ))}
                </div>
              </td>
              <td className={styles.dataCell}>{data.lastUpdatedBy}</td>
              <td className={styles.dataCell}>{data.lastUpdatedOn}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListEvidence;
