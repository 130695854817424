import React from 'react';
import { useContext, useEffect, useState } from "react";
import { Context } from "../contexts/Store";
import AuthHeaders from "./AuthHeaders";
import styles from "./playground.module.css";
import QueryParamsTable from "./QueryParamsTable";
import RequestBody from "./RequestBody";
import RequestHeadersTable from "./RequestHeadersTable";

const PayloadForm = () => {
  const { state } = useContext(Context);
  const [apiSettings, setApiSettings] = useState(
    state.tabs[state.activeTabId - 1]?.payload ? "body" : "qp"
  );
  const [currentTab, setCurrentTab] = useState(
    state.tabs[state.activeTabId - 1]
  );

  useEffect(() => {
    setCurrentTab(state.tabs[state.activeTabId - 1]);
  }, [state]);
  return (
    <div className={styles.payload_panel}>
      <div className={styles.payload_panel_header}>
        <ul
          className={
            state.splitView === "H"
              ? styles.payload_types
              : styles.payload_types_small
          }
        >
          <li
            onClick={(e) => setApiSettings("qp")}
            className={
              apiSettings === "qp"
                ? styles.payload_tab_active
                : styles.payload_tab
            }
          >
            Params
          </li>
          <li
            onClick={(e) => setApiSettings("auth")}
            className={
              apiSettings === "auth"
                ? styles.payload_tab_active
                : styles.payload_tab
            }
          >
            Authorization
            {currentTab?.auth && <span className={styles.green_dot}></span>}
          </li>
          <li
            onClick={(e) => setApiSettings("headers")}
            className={
              apiSettings === "headers"
                ? styles.payload_tab_active
                : styles.payload_tab
            }
          >
            Headers{" "}
            {currentTab?.requestHeaders?.length > 0 && (
              <span className={styles.green_dot}></span>
            )}
          </li>
          <li
            onClick={(e) => setApiSettings("body")}
            className={
              apiSettings === "body"
                ? styles.payload_tab_active
                : styles.payload_tab
            }
          >
            Body{" "}
            {state.tabs[state.activeTabId - 1]?.payload && (
              <span className={styles.green_dot}></span>
            )}
          </li>
          <li className={styles.payload_tab}>Script</li>
          <li className={styles.payload_tab}>Settings</li>
        </ul>
        <button className={styles.cookie} >Cookies</button>
      </div>
      {(() => {
        switch (apiSettings) {
          case "qp":
            return <QueryParamsTable />;
          case "auth":
            return <AuthHeaders />;
          case "headers":
            return <RequestHeadersTable />;
          case "body":
            return <RequestBody />;
          default:
            return null;
        }
      })()}
    </div>
  );
};

export default PayloadForm;
