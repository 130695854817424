import React from 'react'
import styles from "./SearchFilterBar.module.css";

const SearchFilter = ({ setSearchText }) => {
  return (
    <div className={styles.searchFilterContainer}>
      {/* Search by title */}
      <input
        type="search"
        placeholder="Search title"
        onChange={(e) => setSearchText(e.target.value)}
        className={styles.searchInput}
      />

      {/* Filter by status */}
      <select className={styles.filterDropdown}>
        <option value="option1">Filter by</option>
      </select>
    </div>
  );
};

export default SearchFilter;
