/* eslint-disable react/prop-types */
import React, { useState } from "react";
import styles from "./CustomDropdown.module.css";

const CustomDropdown = ({ label, options, onSelect, header, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  const handleItemClick = (value) => {
    setSelectedValue(value);
    setIsOpen(false);
    if (onSelect) onSelect(value);
  };

  return (
    <div className={styles.dropdown}>
      <label className={styles.label}>{label}</label>
      <div
        className={`${styles.dropdownHeader} ${disabled ? styles.disabled : ""}`}
        onClick={() => !disabled && setIsOpen(!isOpen)} // Prevent opening if disabled
      >
        {selectedValue || "Select data category"}
        <svg
          className={styles.arrow}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d={isOpen ? "M7 14l5-5 5 5" : "M7 10l5 5 5-5"} />
        </svg>
      </div>
      <ul className={`${styles.dropdownList} ${isOpen ? "" : styles.hidden}`}>
        {isOpen && header && (
          <div className={styles.dropdownHeaderText}>{header}</div>
        )}
        {options?.map((option, index) => (
          <li
            key={index}
            className={styles.dropdownItem}
            onClick={() => handleItemClick(option)}
          >
            {option}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CustomDropdown;