/* eslint-disable react/prop-types */
import React from "react";
import styles from "./ActionDropdown.module.css";
import { Link,useLocation} from "react-router-dom";

const ActionDropdown = ({
  templateID,
  position,
  handleClose,
  onViewManageTemplate,
  handleRemoveRow,
}) => {
  const handleRemove = () => {
    handleRemoveRow();
    onViewManageTemplate();
  };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userID = queryParams.get("userID");

  return (
    <div
      className={styles.dropdownContent}
      style={{
        top: `${position.top + 20}px`,
        left: `${position.left - 150}px`, // Offset to align with three dots
      }}
    >
      {/* Close Button */}
      <button className={styles.closeButton} onClick={handleClose}>
        ×
      </button>

      <div className={styles.dropdownItems}>
        <Link
          to={`/data-management/manageTemplate?section=2&userID=${userID}&manageTemplateID=${templateID}`}
          className={styles.dropdownItem}
          onClick={onViewManageTemplate}
        >
          View / Manage template
        </Link>

        <hr className={styles.dropdownDivider} />

        <div
          className={`${styles.dropdownItem} ${styles.removeItem}`}
          onClick={handleRemove}
        >
          Remove
        </div>
      </div>
    </div>
  );
};

export default ActionDropdown;
