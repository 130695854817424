import React from 'react'
/* eslint-disable react/prop-types */
import styles from './UploadSuccess.module.css'; // Adjust the path to your CSS module

const UploadSuccess = ({ message }) => {
  return (
    <div className={styles.successContainer}>
      <div className={styles.successIcon}>
        <i className="bi bi-check-circle-fill"></i> {/* Bootstrap icon for checkmark */}
      </div>
      <h2 className={styles.successMessage}>
        {message || "Template uploaded successfully"}
      </h2>
    </div>
  );
};

export default UploadSuccess;