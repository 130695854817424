import React, { useRef, useState } from 'react';
import CustomDropdown from './CustomDropdown/CustomDropdown'; // Import the custom dropdown
import styles from './FileUpload.module.css';
import uploadIcon from '../icons/upload.svg';

const FileUpload = () => {
  const [categorySelected, setCategorySelected] = useState(null);
  const [templateSelected, setTemplateSelected] = useState(null);
  const [mappingKeySelected, setMappingKeySelected] = useState(null);
  const [file, setFile] = useState(null);

  const fileInputRef = useRef(null);

  const categories = ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5', 'unknown'];
  const templates = ['Template 1', 'Template 2' , 'upload without template'];
  const mappingKeys = ['Mapping Key 1', 'Mapping Key 2' , 'Create new'];

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      alert(`File "${selectedFile.name}" selected for upload.`);
    }
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file to upload!');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('category', categorySelected || '');
    formData.append('template', templateSelected || '');
    formData.append('mappingKey', mappingKeySelected || '');

    try {
      const response = await fetch('https://your-api-endpoint.com/upload', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        alert(`File "${file.name}" uploaded successfully!`);
      } else {
        alert('Failed to upload the file. Please try again.');
      }
    } catch (err) {
      console.log(err);
      alert('An error occurred during file upload. Please try again.');
    }
  };

  return (
    <div className={styles.fileUploadCont}>
      <p>
        <b>Tip :</b> Select data category, data template, and mapping key (if applicable) to upload data files.
      </p>
      <div className={styles.dropdownContainer}>
        <CustomDropdown
          label="Select Data Category"
          options={categories}
          header="Select data category"
          onSelect={(value) => {
            setCategorySelected(value);
            setTemplateSelected(null); // Reset template selection when category changes
            setMappingKeySelected(null); // Reset mapping key when category changes
          }}
          disabled={false} // First dropdown is always enabled
        />
        
        <CustomDropdown
          label="Select Data Template"
          options={templates}
          header="Select data template"
          onSelect={(value) => setTemplateSelected(value)}
          disabled={!categorySelected} // Disable until a category is selected
        />
        
        <CustomDropdown
          label="Select Mapping Key"
          options={mappingKeys}
          header="Select mapping key"
          onSelect={(value) => setMappingKeySelected(value)}
          disabled={!templateSelected} // Disable until a template is selected
        />
      </div>

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />

      <button
        className={styles.uploadButton}
        disabled={!mappingKeySelected} // Disable if mapping key is not selected or no file is chosen
        onClick={file ? handleUpload : handleUploadClick}
      >
        <img src={uploadIcon} alt="Upload Icon" className={styles.uploadIcon} />
        Upload
      </button>
    </div>
  );
};

export default FileUpload;