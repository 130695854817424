/* eslint-disable react/prop-types */
import React from "react";
import styles from "./WarningModal.module.css";

const WarningModal = ({ onCancel, onSave }) => {
  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <i className="bi bi-exclamation-triangle-fill"></i>
        </div>
        <div className={styles.modalBody}>
          <p>
            Cannot complete action as completed data uploads will be affected.
            You may deactivate the existing template and upload a new one.
          </p>
        </div>
        <div className={styles.modalFooter}>
          <button className={styles.cancelButton} onClick={onCancel}>
            Cancel
          </button>
          <button className={styles.saveButton} onClick={onSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default WarningModal;