import React from 'react'
/* eslint-disable react/prop-types */
import { useLocation, useHistory } from "react-router-dom";
import styles from "./SaveCancelButton.module.css";
const SaveCancelButton = ({isMappingScreen}) => {

  const location = useLocation().pathname;
  const navigate = useHistory();

  const handleClick = () => {
    navigate.push('/data-management/');
  }


  return (
    <div className={styles.actions}>
      <button className={`${styles.cancelButton} ${styles.cancelandSave}`} onClick={handleClick}><i className="bi bi-x-lg"></i> Cancel</button>
      <div className={styles.saveCreateButton}>
        {isMappingScreen?<button className={`${styles.saveButton} ${styles.cancelandSave}`}><i className="bi bi-floppy2-fill"></i>Save</button>:null}
        <button className={styles.createButton}><i className="bi bi-grid-3x3-gap-fill"></i>{location === "/fileUploadWithTemplate" ? "Finish Editing" : "Create Data Table"}</button>
      </div>
    </div>
  )
}

export default SaveCancelButton