import React from 'react'
/* eslint-disable react/prop-types */
import styles from "../ShowDataTable.module.css";
import TableCell from "./TableCell";
import ActionDropdown from "../ActionDropDown/ActionDropdown";

const TableRow = ({
  row = [],
  rowIndex,
  handleCellClick,
  handleCellEdit,
  handleRowEdit,
  handleClose,
  handleDropdownClick,
  handleViewAndManageTemplate,
  handleRemoveRow,
  editingCell = {},
  dropdownState = {},
  isTemplateSection = false,
  isHomeSection = false,
}) => {
  return (
    <>
      {/* Checkbox Cell */}
      <td className={styles.tableCell}>
        <label className="custom-checkbox">
          <input type="checkbox" />
          <span className="checkmark"></span>
        </label>
      </td>

      {/* Row Index */}
      <td className={styles.tableCell}>
        <span>{rowIndex + 1}</span>
      </td>

      {/* Table Cells */}
      {row?.slice(1)?.map((cell, cellIndex) => (
        <td
          key={cellIndex}
          className={`${styles.tableCell} ${styles.tableCellData}`}
          onClick={() => handleCellClick(rowIndex + 1, cellIndex)}
        >
          {cell !== "Actions" ? (
            <TableCell
              value={cell}
              isEditing={
                editingCell.rowIndex === rowIndex + 1 &&
                editingCell.cellIndex === cellIndex
              }
              onChange={(e) => handleCellEdit(rowIndex + 1, cellIndex, e)}
            />
          ) : (
            <div className={styles.dropdowncontainer}>
              {/* Edit Button with SVG Icon */}
              <button
                className={styles.button}
                onClick={() => handleRowEdit(rowIndex)}
              >
                <svg
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  width="35"
                  height="16"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12.9 6.858l4.242 4.243L7.242 21H3v-4.243l9.9-9.9zm1.414-1.414l2.121-2.122a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414l-2.122 2.121-4.242-4.242z"></path>
                </svg>
              </button>

              {/* Dropdown Button */}
              <button
                className={styles.button}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDropdownClick(rowIndex + 1, row.length, e);
                }}
              >
                <i className="bi bi-three-dots" style={{color:"#796acc"}}></i>
              </button>

              {/* Dropdown Menu */}
              {dropdownState?.showOptions?.rowIndex === rowIndex + 1 &&
                dropdownState?.showOptions?.cellIndex === row.length &&
                (isTemplateSection || isHomeSection) && (
                  <ActionDropdown
                    templateID={row[0]}
                    handleClose={handleClose}
                    position={dropdownState?.position}
                    onViewManageTemplate={handleViewAndManageTemplate}
                    handleRemoveRow={() => handleRemoveRow(row[0])}
                  />
                )}
            </div>
          )}
        </td>
      ))}
    </>
  );
};

export default TableRow;
