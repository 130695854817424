import React from 'react';
import { useContext } from "react";
import { Context } from "../contexts/Store";
import RightPanel from "./RightPanel";
import Toolbar from "./Toolbar";
import URLBox from "./URLBox";
import PayloadForm from "./PayloadForm";
import ResponseViewer from "./ResponseViewer";
import Overview from "./Overview";
import styles from "./playground.module.css";
import "bootstrap-icons/font/bootstrap-icons.css"; // Import Bootstrap icons

const Playground = () => {
  const { state } = useContext(Context);

  return (
    <main className={styles.wrapper}>
      <Toolbar />
      {state.overviewTab === "shown" ? (
        <Overview />
      ) : (
        <div
          className={
            state.infoPanelOpened ? styles.main : styles.main_collapsed
          }
        >
          <div className={styles.container}>
            <div className={styles.panelheader}>
              <div className={styles.title_area}>
                <button className={styles.http_req}></button>
                <div className={styles.title_text}>
                {state.tabs[state.activeTabId - 1]?.url || "no_name_request"}
                </div>
              </div>
              <div className={styles.options_area}>
                <div className={styles.save_options}>
                  <button type="button">
                    <i className="bi bi-floppy"></i>
                    <span
                      className="save_text"
                      style={{ "paddingLeft": "5px" }}
                    >
                      Save
                    </span>
                  </button>
                </div>
                <div className={styles.view_options}>
                  <button className={styles.chevron_btn}>
                    <i
                      className="bi bi-chevron-down"
                      style={{ color: "black", fontSize: "small" }}
                    ></i>
                  </button>
                  <button
                    className={styles.share_btn}
                    style={{
                      border: "2px solid var(--border-color)",
                      borderRadius: "5px",
                      marginRight: "5px",
                    }}
                  >
                    Share
                  </button>
                </div>
              </div>
            </div>
            <URLBox />
            <div
              className={
                state.splitView === "V"
                  ? styles.panel_vertical
                  : styles.panel_horizontal
              }
            >
              <PayloadForm />
              <ResponseViewer />
            </div>
          </div>
          <div
            className={
              state.infoPanelOpened
                ? styles.panel_opened
                : styles.panel_collapsed
            }
          >
            <RightPanel />
          </div>
        </div>
      )}
    </main>
  );
};

export default Playground;
