import React from "react";
import styles from "./ProgressBar.module.css";

const ProgressBar = ({ currentStep }) => {
  const steps = [
    { id: 1, name: "Field Mapping" },
    { id: 2, name: "Data Verification" },
    { id: 3, name: "Attach Evidence" },
  ];

  return (
    <div className={styles.progressBar}>
      {steps.map((step, index) => (
        <div key={step.id} className={styles.step}>
          {/* Number or Tick Mark */}
          <div
            className={`${styles.circle} ${
              currentStep > step.id ? styles.completed : currentStep === step.id ? styles.active : ""
            }`}
          >
            {currentStep > step.id ? "✔" : step.id}
          </div>
          {/* Step Label */}
          <div
            className={`${styles.stepLabel} ${
              currentStep >= step.id ? styles.active : ""
            }`}
          >
            {step.name}
          </div>
          {/* Connector Line */}
          {index < steps.length - 1 && (
            <div
              className={`${styles.connector} ${
                currentStep > step.id ? styles.active : ""
              }`}
            ></div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ProgressBar;