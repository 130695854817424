/* eslint-disable react/prop-types */
import React, { useState } from "react";
import styles from "./UploadEvidence.module.css";

const UploadEvidence = ({ dropDownOptions }) => {
  const [files, setFiles] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // Search term for filtering
  const [filteredOptions, setFilteredOptions] = useState(dropDownOptions); // Filtered dropdown options
  const [isOpen, setIsOpen] = useState(false); // Toggle file list visibility
  const [selectedLocation, setSelectedLocation] = useState(""); // Selected location

  // Handle file selection
  // Handle file selection
  const handleFileSelect = (e) => {
    const selectedFiles = Array.from(e.target.files);

    setFiles((prevFiles) => {
      const existingFileNames = prevFiles.map((file) => file.name);
      const filteredFiles = selectedFiles.filter(
        (file) => !existingFileNames.includes(file.name)
      );

      return [...prevFiles, ...filteredFiles];
    });
  };

  // Handle drag and drop
  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);

    setFiles((prevFiles) => {
      const existingFileNames = prevFiles.map((file) => file.name);
      const filteredFiles = droppedFiles.filter(
        (file) => !existingFileNames.includes(file.name)
      );

      return [...prevFiles, ...filteredFiles];
    });
  };

  // Prevent default behavior for drag events
  const preventDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };


  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredOptions(
      dropDownOptions?.filter((option) =>
        option.toLowerCase().includes(value)
      )
    );
  };

  const handleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    setSearchTerm("");
    setFilteredOptions(dropDownOptions);
  }

  const handleSelectLocation = (option) => {
    setSelectedLocation(option);
    setDropdownOpen(false);
  };

  return (
    <div className={styles.uploadContainer}>

      <button className={styles.backButton}>
        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 1.42L3.42 6L8 10.59L6.59 12L0.59 6L6.59 1.23266e-07L8 1.42Z" fill="#1232C3" />
        </svg>
        Back
      </button>

      {/* File Upload Section */}
      {/* Drag & Drop Box */}
      <div
        className={styles.uploadBox}
        onDragOver={preventDefaults}
        onDrop={handleDrop}
      >
        <p className={styles.uploadText}>
          Drag & drop files here or upload
          <span className={styles.supportedTypes}>
            {" "}
            Supported files type : .pdf, .doc, .xls
          </span>
        </p>
        <label className={styles.uploadButton}>
          <input
            type="file"
            multiple
            className={styles.hiddenInput}
            onChange={handleFileSelect}
          />
          <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0V1.875H13V0H0ZM0 9.375H3.71429V15H9.28571V9.375H13L6.5 2.8125L0 9.375Z" fill="#1232C3" />
          </svg>
          Upload
        </label>
      </div>

      {/* File List Info */}
      <div className={styles.filesInfo}>
        <a
          href="#"
          className={styles.filesUploaded}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          {files.length} files uploaded{" "}
          <span className={styles.arrow}>
            <svg style={{
              transform: isOpen ? "rotate(90deg)" : "rotate(270deg)",
              transition: "transform 0.3s ease",
            }} width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 1.42L3.42 6L8 10.59L6.59 12L0.59 6L6.59 1.23266e-07L8 1.42Z" fill="#1232C3" />
            </svg>
          </span>
        </a>
      </div>

      {/* File List */}
      {isOpen && files.length > 0 && (
        <div className={styles.fileList}>
          <ul>
            {files.map((file, index) => (
              <li key={index} className={styles.fileItem}>
                {file.name}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Form Section */}
      <div className={styles.formSection}>
        <div className={styles.inputGroup}>
          <label>Date of the File</label>
          <input type="date" />
        </div>
        <div className={styles.inputGroup}>
          <label>Location</label>
          <div className={styles.dropdown}>
            <div
              className={`${styles.locationDropdown}`}
              onClick={handleDropdown}
            >
              <p>{selectedLocation || "Select a location"}</p>
              <svg style={{
                transform: dropdownOpen ? "rotate(90deg)" : "rotate(270deg)",
                transition: "transform 0.3s ease",
              }} width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 1.42L3.42 6L8 10.59L6.59 12L0.59 6L6.59 1.23266e-07L8 1.42Z" fill="#1232C3" />
              </svg>
            </div>
            {dropdownOpen && (
              <div className={styles.dropdown}>
                <ul className={`${styles.dropdownList} ${dropdownOpen ? "" : styles.hidden}`}>
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearch}
                    className={styles.searchInput}
                  />
                  <button
                    className={styles.closeButton}
                    onClick={handleDropdown}
                  >
                    &times;
                  </button>
                  {filteredOptions?.map((option, index) => (
                    <li
                      key={index}
                      className={styles.dropdownItem}
                      onClick={() => handleSelectLocation(option)}
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className={styles.inputGroup}>
          <label>Audited on</label>
          <input type="date" />
        </div>
        <div className={styles.inputGroup}>
          <label>Audited by</label>
          <input type="text" />
        </div>
      </div>

      {/* Buttons */}
      <div className={styles.actionButtons}>
        <button className={styles.addMore}><span>+</span>Add more</button>
        <div className={styles.cancelAndSave}>
          <button className={styles.cancelButton}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.46 12L19 17.54V19H17.54L12 13.46L6.46 19H5V17.54L10.54 12L5 6.46V5H6.46L12 10.54L17.54 5H19V6.46L13.46 12Z" fill="#1232C3" />
            </svg>
            Cancel
          </button>
          <button className={styles.saveButton}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.58984 16.58L13.1698 12L8.58984 7.41L9.99984 6L15.9998 12L9.99984 18L8.58984 16.58Z" fill="#F7F7F8" />
            </svg>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadEvidence;