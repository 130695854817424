import React , { Component } from 'react';
import DashboardHeader from "../includes/dashboardHeader/DashboardHeader";
import DashboardSidebar from "../includes/dashboardSidebar/DashboardSidebar";
import SidebarJs from '../../common/js/sidebarAnimation';
import FooterComponent from "../includes/dashboardFooter/FooterComponent";
import {Prompt} from 'react-router-dom';
import AsideComponent from "../includes/asideComponent/AsideComponent";
import {BalancedScorecardWrapper} from './styling/balancedScorecard';
import BalancedScorecardLeftbar from './balancedScorecardLeftbar';
import BalancedScorecardBreadcrumb from './balancedScorecardBreadcrumb';
import BalancedScorecardGoalSetting from './balancedScorecardGoalSetting';
import BalancedScorecardGrantChart from './balancedScorecardGrantChart';
import BalancedScorecardDependencyTabular from './balancedScorecardDependencyTabular';
import BalancedScorecardObjectivesTarget from './balancedScorecardObjectivesTargets';
import CardSettingKpi from '../Kpi/CardSettingKpi';
import {jsPlumb,jsPlumbUtil} from 'jsplumb';
import { Link, withRouter } from 'react-router-dom';
import { fetchBalancedScorecardKpi, fetchBalancedScorecardCategories, fetchAllBalancedScorecards,fetchAllKpiAgainstBalancedScorecards } from '../../actions/balancedScorecardActions';
import { startLoader, stopLoader } from '../../actions/loaderActions';
import { connect } from 'react-redux';
import {responseMessage} from '../../utils/alert';
import ModalPopup from '../common/ModalPopup';
import ApiServer from "./../../common/js/ApiServices.js";
import axios from 'axios';
import CacheStorage from '../../utils/CacheStorage';
import { confirmAlert } from 'react-confirm-alert';
import swal from "sweetalert"; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Gantt } from 'gantt-task-react';
import "../../../node_modules/gantt-task-react/dist/index.css";

const config = require('../../config');

const ddoptions = [
    {
      id: 1,
      name: "Leanne Graham"
    },
    {
      id:  2,
      name: "Ervin Howell"
    }
  ];

const chartCanvas = [
    '#ffdf83',
    '#fbb5b5',
    '#b2e1ef', 
    '#e5c62d',
    '#a2f788',
    '#e5c62d',
    '#fffbbc',
    '#efad6b',
    '#ffff4d',
    '#cdcd04',
    '#ffbeaf',
  ];

class BalancedScorecard extends Component{
    constructor(props){
        super(props);
        this.sidebarAnimation=new SidebarJs();
        this.apiServer = new ApiServer(true);
    }

    state = {
        color : "yellow",
        ovalId : 1,
        ovalShape : [],
        ovalData:[],
        goalSetting : [{
            "id": 0,
            "title": "Vision",
            "description": "Double Click to Edit"
        },{
            "id": 0,
            "title": "Purpose",
            "description": []
        },{
            "id": 0,
            "title": "StrategicPriorities",
            "description": []
        },{
            "id": 0,
            "title": "StrategicResults",
            "description": []
        }],
        categoryRows : [],
        kpiData: {
            data: []
        },
        bscId : this.props.match.params.bscId?this.props.match.params.bscId:0,
        bscName : "",
        connections : [],
        showCardKpi:false,
        showGoalSetting:false,
        showNameValidation : false,
        selectBscModalOpen : false,
        reArrangePopUp : false,
        kpiDragging : false,
        BalancedScorecardGoalSettingModal:false,
        showGrantTaskPanel: false,
        modificationStarted:false,
        showDependencyPanel: false,
        categoryNameKpiNameControlLevers:[],
        circular : 'N',
        latestDependencyIdtoCheckCircular: '',
        controlLeversDependencyBridge:[],
        gantttasks:[
            {
                id: "1.1.1",
                name: "",
                start: new Date(new Date().getTime()+(1*24*60*60*1000)),
                end: new Date(new Date().getTime()+(30*24*60*60*1000)),
                progress: 10
              }
          ],
        controlLeversTitleModal:'',
        controlLeverTargetModal:'',
        controlLeverExpectedGainModal:'',
        controlLeverUnitOfMeasurementModal:'',
        showcontrolLeversModal:false,
        categoryChartLegend:[],
        subKpiDD:[]

    }

    
    
/**************************************** New modification done by himadri on 7-11-2023 *******************************************/

componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeunload.bind(this));
}

beforeunload(e) {  
    if (this.state.modificationStarted) {
      e.preventDefault();
      e.returnValue = true;
    }
}

getStartEndDateForProject = (tasks, projectId) => {
    const projectTasks = tasks.filter((t) => t.project === projectId);
    let start = projectTasks[0].start;
    let end = projectTasks[0].end;
  
    for (let i = 0; i < projectTasks.length; i++) {
      const task = projectTasks[i];
      if (start.getTime() > task.start.getTime()) {
        start = task.start;
      }
      if (end.getTime() < task.end.getTime()) {
        end = task.end;
      }
    }
    return [start, end];
  };

handleTaskChange = (task) => {
    console.log("On date change Id:" + task.id);
    let newTasks = this.state.gantttasks.map((t) => (t.id === task.id ? task : t));
    if (task.project) {
      const [start, end] = this.getStartEndDateForProject(newTasks, task.project);
      const project =
        newTasks[newTasks.findIndex((t) => t.id === task.project)];
      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map((t) =>
          t.id === task.project ? changedProject : t
        );
      }
    }

    this.setState({
        gantttasks : newTasks
    })
  };

generateGranttTaskChart =()=>{

    let categoryNameKpiNameControlLevers = this.state.categoryNameKpiNameControlLevers;

    let checkDependencyOccuranceArr = [];
    let colorIdx = 0;    
    let chartLegend = [];
    let subKpiDD = [];

    let hasSubKPI = false;

    categoryNameKpiNameControlLevers.map((row, rowInd)=>{        
        row.Kpi.map((kpi,ind)=>{
            kpi.ControlLevers.map((controlLever,controlLeverInd)=>{
               //subKpiDD.push({'value':controlLever.DependencyId, 'label':controlLever.ControlLeversTitle});
               if(controlLever.ControlLeversTitle != null && controlLever.KPIControlLeversID != null)
               {
                    if(controlLever.DisplayDependency.length >1)
                    {
                        let answ = controlLever.DisplayDependency.split(',');
                        answ.forEach(function(obj){
                            checkDependencyOccuranceArr.push(obj);
                        });
                        
                    }

               }
                
                //hasSubKPI = true;
            });
        });    
    });
    //console.log('---hb-dep');
    //console.log(checkDependencyOccuranceArr);

    const occurrences = checkDependencyOccuranceArr.reduce(function (acc, curr) {
        return acc[curr] ? ++acc[curr] : acc[curr] = 1, acc
      }, {});

      //console.log(occurrences);  

    let grantTastArr = [];
    
   
    let datecounter = 0;
    let hBendDate = '';
    categoryNameKpiNameControlLevers.map((row, rowInd)=>{
        
        row.Kpi.map((kpi,ind)=>{

            colorIdx = (colorIdx + 1) % chartCanvas.length;
            chartLegend.push({'legendID':kpi.KpiId,'legendName':kpi.KpiName,'legendColor':chartCanvas[colorIdx]});

            kpi.ControlLevers.map((controlLever,controlLeverInd)=>{
                
                if(controlLever.ControlLeversTitle != null && controlLever.KPIControlLeversID != null)
                {
                    hasSubKPI = true;
                    subKpiDD.push({'value':controlLever.DependencyId, 'label':controlLever.ControlLeversTitle});

                }
                
               
                let grantTaskDependencyArray = [];
                
                let progressChart = 1;

                let depOccurence = 0;

                if(occurrences[controlLever.DependencyId])
                {
                    //console.log(occurrences[controlLever.DependencyId]);
                    depOccurence = depOccurence+occurrences[controlLever.DependencyId];
                }
                 //console.log(depOccurence);   

                if(controlLever.DisplayDependency.length >1)
                {
                    grantTaskDependencyArray = controlLever.DisplayDependency.split(',');                    
                    
                }                

                if(controlLever.DisplayDependency.length >1)
                {
                    progressChart = 100;

                }
                //console.log('--hb--');
                //console.log(depOccurence);              

                /* if(datecounter >0)
                {
                  var newStartDate = new Date(new Date(hBendDate).getTime()+((1*5)*24*60*60*1000));
                    
                }else{

                    var newStartDate = new Date(new Date().getTime()+(0*24*60*60*1000));  

                }
                console.log('newStartDate',newStartDate);
               

                let today = new Date();
                let dd = today.getDate();

                hBendDate = new Date(new Date(newStartDate).getTime()+(20*24*60*60*1000));
                console.log('hBendDate',hBendDate);   */             
                
                var newStartDate = new Date(new Date(controlLever.start_date).getTime()); 
                //console.log('newStartDate',newStartDate);
                var newEndDate = new Date(new Date(controlLever.end_date).getTime()); 
                //console.log('newEndDate',newEndDate);
                if(controlLever.ControlLeversTitle != null && controlLever.KPIControlLeversID != null)
                {
                    grantTastArr.push({ 
                        id: controlLever.DependencyId,
                        kpiControlLeversID:controlLever.KPIControlLeversID,
                        name: controlLever.ControlLeversTitle,                    
                        start: newStartDate,
                        end: newEndDate,
                        progress: 100,
                        dependencies: grantTaskDependencyArray,
                        styles: { 
                            fontColor:"#000000", 
                            progressColor: chartCanvas[colorIdx], 
                            progressSelectedColor: chartCanvas[colorIdx], 
                            BackgroundColor: chartCanvas[colorIdx], 
                            arrowColor:chartCanvas[colorIdx] 
                        }
                    });

                }
                    
                datecounter++;
            });
        });    
    });
    
    if(hasSubKPI)
    {
        //console.log('grantTastArr',grantTastArr);
        this.setState({
            showGrantTaskPanel:true,
            gantttasks : grantTastArr,
            categoryChartLegend:chartLegend,
            subKpiDD:subKpiDD
        })

    }
    

}

resetState()
{
    this.setState({
        categoryRows : [],
        showCardKpi:false,
        showGrantTaskPanel: false,
        showDependencyPanel: false,
        modificationStarted : false,
        categoryNameKpiNameControlLevers:[],
        controlLeversDependencyBridge:[],
        gantttasks:[
            {
                id: "1.1.1",
                name: "Redesign website",
                start: new Date('2023-12-01'),
                end: new Date('2023-12-31'),
                progress: 10
            }
        ],
        controlLeversTitleModal:'',
        controlLeverTargetModal:'',
        controlLeverExpectedGainModal:'',
        controlLeverUnitOfMeasurementModal:'',
        showcontrolLeversModal:false
    });
}
 
getCategoryNameKpiNameControlLevers = async (id) => {
    this.resetState();

    let res_data =  [];

    //alert(this.props.allBsc.length);

    this.props.startLoader();
        let bsc = await axios.get(config.laravelBaseUrl + "getGlobalBusinessInitiative/" +id,{
            headers: {
            "authorization": "Bearer " + CacheStorage.getItem("userToken")
            }
        }).then(result => {
            //console.log(result.data.data);
            this.props.stopLoader();
            res_data =  result.data.data;
            //alert(res_data.length);
            if(res_data.length > 0)
            {
                this.setState({
                    categoryNameKpiNameControlLevers : res_data,
                    showDependencyPanel: true,
                    showCardKpi:true,
                },()=>{this.generateGranttTaskChart()})
            }else{
                responseMessage("warn", "No data found!", "");
            }
            

    //alert('ok'+id);
    
            
          }).catch(error => {
            //this.props.stopLoader();
            this.setState({
                categoryRows : [],
                showGrantTaskPanel: false,
                showDependencyPanel: false,
                categoryNameKpiNameControlLevers:[],
                controlLeversDependencyBridge:[],
                gantttasks:[
                    {
                        id: "1.1.1",
                        name: "Redesign website",
                        start: new Date('2023-12-01'),
                        end: new Date('2023-12-31'),
                        progress: 10
                    }
                ],
                controlLeversTitleModal:'',
                controlLeverTargetModal:'',
                controlLeverExpectedGainModal:'',
                controlLeverUnitOfMeasurementModal:'',
                showcontrolLeversModal:false
            })
            responseMessage("error", "Sorry something went wrong. Please try again", "");
            console.log(error);
          });
        
 
    
}

checkCircularDependency = async (categoryNameKpiNameControlLevers,controlLeversDependencyBridge,dependencyPredecessorId) => {

    /* ---------------- New section to check circular dependency ------------------------- */

    let dependencyMappingJsondata = JSON.stringify(categoryNameKpiNameControlLevers);

    let data = {
        "dependencyMappingJsondata":dependencyMappingJsondata
    }

    return this.apiServer
    .SendRequest({
      method: "POST",
      url: "/checkCircularDependencyMapping",
      data: data
    })
    .then(result => {
      //console.log('checkCircularDependencyMapping',result);
      if (result.success === true) 
      {
        if(result.message == 'false')
        {
            if(this.state.circular == 'Y')
            {
                responseMessage("success", 'Circular dependency removed successfully', "");
            }
            //alert('not circular');
            this.setState({
            categoryNameKpiNameControlLevers : categoryNameKpiNameControlLevers,
            controlLeversDependencyBridge: controlLeversDependencyBridge,
            circular : 'N',
            latestDependencyIdtoCheckCircular: ''
        },()=>{
             this.generateGranttTaskChart()   
        })
        }else if(result.message == 'true')
        {
            //alert('Circular');
            responseMessage("Error", result.data+"", ""); 
            this.setState({
                circular : 'Y',
                latestDependencyIdtoCheckCircular: dependencyPredecessorId
            });
            
        }
        
        
      }
    })
    .catch(error => {
        responseMessage("Error", "Something went wrong while checking circular dependency!", "");  
    });    
    /* --------------- check circular dependency ends here ------------------------------- */

}

changeSubKpiDependencyDropdown(kpiControlLeversID, evt, DependencyId){
    //alert('ok'+kpiControlLeversID);
    //console.log('HB-',evt);

    let dependencyString = '';
    let olddependencyString = '';
    let dependencyPredecessorId = '';

    if(evt.length > 0)
    {
        let evtArr = [];
        evt.forEach(function(evtobj){
            //console.log(ddoptionsobj);
            evtArr.push(evtobj.value);   
            dependencyPredecessorId = DependencyId+'-'+evtobj.value;         
        }); 

        dependencyString = evtArr.join();

    }

    let controlLeversDependencyBridge = [];
    let categoryNameKpiNameControlLevers = this.state.categoryNameKpiNameControlLevers;

    categoryNameKpiNameControlLevers.map((row, rowInd)=>{
        row.Kpi.map((kpi,ind)=>{
            kpi.ControlLevers.map((controlLever,controlLeverInd)=>{
                if(controlLever.KPIControlLeversID == kpiControlLeversID)
                {
                    olddependencyString = categoryNameKpiNameControlLevers[rowInd]['Kpi'][ind]['ControlLevers'][controlLeverInd]['DisplayDependency'];
                    categoryNameKpiNameControlLevers[rowInd]['Kpi'][ind]['ControlLevers'][controlLeverInd]['DisplayDependency'] = dependencyString;
                }
                
                controlLeversDependencyBridge.push({
                    'KPIControlLeversID':controlLever.KPIControlLeversID,
                    'DisplayDependencyId':controlLever.DependencyId
                });
            });
        });    
    });
    
    if(olddependencyString === dependencyString)
    {
        // no modificationStarted
    }else{
        this.setState({
            modificationStarted : true
        },()=>{
            this.checkCircularDependency(categoryNameKpiNameControlLevers,controlLeversDependencyBridge,dependencyPredecessorId);   
        })

        //console.log('HB-',olddependencyString+'==>'+dependencyString);
    }
    
    
    /* this.setState({
        categoryNameKpiNameControlLevers : categoryNameKpiNameControlLevers,
        controlLeversDependencyBridge: controlLeversDependencyBridge
    },()=>{
         this.generateGranttTaskChart()   
    }) */
}

markModificationStarted = () => {
    this.setState({
        modificationStarted : true
    });
}

changeSubKpiDependency = (kpiControlLeversID, evt, DependencyId) => {
    //console.log('kpiControlLeversID='+kpiControlLeversID);
    //console.log(evt.target.value);
    let dependencyString = evt.target.value;

    var str = dependencyString;    
    var str_array = str.split(',');

    if(str_array.includes(DependencyId))
    {
        alert('Invalid dependency');
        return;
    }

    if (str.indexOf(',') > -1)
    {
      //alert("comma found inside your_string");
      var subkpi_str_array = str.split(',');
      var comma_occurance = (str.split(",").length - 1);

      //console.log('comma occurance='+comma_occurance);

      if(subkpi_str_array.length > 0)
        {
            //console.log('--hb--');
            //console.log(subkpi_str_array);
            for (var i = 0; i < comma_occurance; i++) {                
                var item = subkpi_str_array[i];
                //Do something
                var subkpi_wbs = item.split('.');
                if(subkpi_wbs.length >= 1 && subkpi_wbs.length < 3)
                {
                    //console.log('----invalid----');
                    alert('Invalid Sub KPI serial number');
                    return;
                }
            }
            
        }
      
    }
    
    

    let controlLeversDependencyBridge = [];
    let categoryNameKpiNameControlLevers = this.state.categoryNameKpiNameControlLevers;

    categoryNameKpiNameControlLevers.map((row, rowInd)=>{
        row.Kpi.map((kpi,ind)=>{
            kpi.ControlLevers.map((controlLever,controlLeverInd)=>{
                if(controlLever.KPIControlLeversID == kpiControlLeversID)
                {
                    categoryNameKpiNameControlLevers[rowInd]['Kpi'][ind]['ControlLevers'][controlLeverInd]['DisplayDependency'] = dependencyString;
                }
                
                controlLeversDependencyBridge.push({
                    'KPIControlLeversID':controlLever.KPIControlLeversID,
                    'DisplayDependencyId':controlLever.DependencyId
                });
            });
        });    
    });

    this.setState({
        categoryNameKpiNameControlLevers : categoryNameKpiNameControlLevers,
        controlLeversDependencyBridge: controlLeversDependencyBridge
    })
}

saveExistingGoalSettings = async () => {
    let id = this.state.bscId;
    let title = this.state.bscName;
    let goalSetting = this.state.goalSetting;
    let strategicObjectives = this.state.categoryRows;
    let ovalsArr = [];
    
    if(this.state.bscName.trim() !== ""){
       
        let result = await axios.post( config.laravelBaseUrl + "saveBscConfig",{
                id : id,
                title : title,
                goalsetting : goalSetting,
                strategicobjective : strategicObjectives,
                Ovals : [],
                connections : []
            }, {
                headers: {
                "authorization": "Bearer " + CacheStorage.getItem("userToken")
                }
            }
        )
        
    }
}

saveBalancedScorecardHandler = async () => {
    //alert('saved');
    //console.log(this.state.controlLeversDependencyBridge);
    this.props.startLoader();

    let categoryNameKpiNameControlLevers = this.state.categoryNameKpiNameControlLevers;

    let controlLeversDependencyBridge = [];

    categoryNameKpiNameControlLevers.map((row, rowInd)=>{
        row.Kpi.map((kpi,ind)=>{
            kpi.ControlLevers.map((controlLever,controlLeverInd)=>{                               
                controlLeversDependencyBridge.push({
                    'KPIControlLeversID':controlLever.KPIControlLeversID,
                    'DisplayDependencyId':controlLever.DependencyId
                });
            });
        });    
    });
    
    let realDependencyMapping = [];

    categoryNameKpiNameControlLevers.map((row, rowInd)=>{
        row.Kpi.map((kpi,ind)=>{
            kpi.ControlLevers.map((controlLever,controlLeverInd)=>{
                if(controlLever.DisplayDependency.length > 1)
                {
                    var str = controlLever.DisplayDependency;
                    var str_array = str.split(',');

                    for(var i = 0; i < str_array.length; i++) {
                    // Trim the excess whitespace.
                    str_array[i] = str_array[i].replace(/^\s*/, "").replace(/\s*$/, "");
                    // Add additional code here, such as:
                    //alert(str_array[i]);
                    let displayDepencendy = str_array[i];
                    let subDependencyArr = '';
                    controlLeversDependencyBridge.map((dbrow, dbrowInd)=>{
                        if(dbrow.DisplayDependencyId == displayDepencendy)
                        {
                            subDependencyArr = dbrow.KPIControlLeversID;
                        }
                    });

                    realDependencyMapping.push({'KPIControlLeversID':controlLever.KPIControlLeversID, 'DependencyKPIControlLeversIDs':subDependencyArr});
                    }
                }

                                
            });
        });    
    });
    
    //console.log(realDependencyMapping);
    this.generateGranttTaskChart();
    /* let selfObj = this;
    setTimeout(function(){              
        selfObj.props.stopLoader();
    },1000); */

    if(this.state.showGoalSetting)
    {
        this.saveExistingGoalSettings();
    }
    

    let dependencyJsondata = JSON.stringify(realDependencyMapping);
    let goalSetting = this.state.goalSetting;

    let data = {
        "BalanceScoreCardId":parseInt(this.state.bscId),
        "DependencyJsondata":dependencyJsondata,
        "gantttasks":this.state.gantttasks,
        "goalSetting":goalSetting
    }
    let selfObj = this;
    await axios.post(config.laravelBaseUrl+'setGlobalBusinessInitiative', data, {
        headers: {
            "authorization": "Bearer " + sessionStorage.getItem("userToken")
        }
    }).then(function(response){
      selfObj.props.stopLoader();        
        responseMessage("success", 'Data saved successfully', "");
        selfObj.getCategoryNameKpiNameControlLevers(selfObj.state.bscId);
        
    }).catch(function(error) {
      selfObj.props.stopLoader();
        responseMessage("Error", "Something went wrong!", "");
        
    });
}

toggleshowcontrolLeversModal = () => {
    this.setState({
        showcontrolLeversModal : !this.state.showcontrolLeversModal
    })
}

handleGrantTaskClick = (task) => {
    console.log("On Click event Id:" + task.id);
    console.log("On Click kpiControlLeversID:" + task.kpiControlLeversID);
    //alert(task.kpiControlLeversID);

    let categoryNameKpiNameControlLevers = this.state.categoryNameKpiNameControlLevers;
    let controlLeversTitle = "";
    let controlLeverTarget = "Not available";
    let controlLeverExpectedGain = "Not available";
    let controlLeverUnitOfMeasurement = "Not available";

    categoryNameKpiNameControlLevers.map((row, rowInd)=>{
        row.Kpi.map((kpi,ind)=>{
            kpi.ControlLevers.map((controlLever,controlLeverInd)=>{
                if(controlLever.DependencyId == task.id)
                {
                    controlLeversTitle = controlLever.ControlLeversTitle;                    
                    controlLeverTarget = controlLever.ControlLeverTarget;

                    //controlLeverUnitOfMeasurement = controlLever.UnitOfMeasurement;
                    //controlLeverExpectedGain = (controlLever.UnitOfMeasurement == 'percent')? controlLever.ExpectedGainPercentage+'%':controlLever.ExpectedGainAmount;

                    if(controlLever.UnitOfMeasurement == 'quantity'){

                        controlLeverUnitOfMeasurement = 'Quantity Only';
                        controlLeverExpectedGain = controlLever.ExpectedTargetGrowthQty;

                    }else if(controlLever.UnitOfMeasurement == 'PercentageQty'){

                        controlLeverUnitOfMeasurement = 'Percentage Qty';
                        controlLeverExpectedGain = controlLever.ExpectedTargetQtyGrowthPercent;

                    }else if(controlLever.UnitOfMeasurement == 'amount'){

                        controlLeverUnitOfMeasurement = 'Amount Based Target';
                        controlLeverExpectedGain = '$'+controlLever.ExpectedGainAmount;

                    }else if(controlLever.UnitOfMeasurement == 'percent'){

                        controlLeverUnitOfMeasurement = 'Percentage';
                        controlLeverExpectedGain = controlLever.ExpectedGainPercentage;

                    }

                }

                                
            });
        });    
    });

    this.setState({
        controlLeversTitleModal : controlLeversTitle,
        controlLeverTargetModal: controlLeverTarget,
        controlLeverExpectedGainModal:controlLeverExpectedGain,
        controlLeverUnitOfMeasurementModal:controlLeverUnitOfMeasurement,
        showcontrolLeversModal:true

    });    
    //alert('ok');
    /* confirmAlert({
        overlayClassName: "react-confirm-custom-overlay",
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <div class="card" style={{border: "1px solid", width:"450px"}}>
                <div class="card-header bbg-warning-custom" style={{minHeight: "45px"}}>
                    <h3 class="box-headerlabel card_kpi_data"  style={{fontSize: "18px"}}>{controlLeversTitle}</h3>
                </div>
                <div class="card-body">
                    <div>
                        <h4 class="box-body-label" style={{fontSize: "18px"}}>Expected Target</h4>
                        <h2 class="box-body-value" style={{fontSize: "16px"}}>{controlLeverTarget}</h2>
                    </div>
                    <div class="mt-4">
                        <h4 class="box-body-label" style={{fontSize: "18px"}}>Expected Gain</h4>
                        <h2 class="box-body-value" style={{fontSize: "16px"}}>{controlLeverExpectedGain}</h2>
                    </div>
                    <div class="mt-4">
                        <h4 class="box-body-label" style={{fontSize: "18px"}}>Unit Of Measurement</h4>
                        <h2 class="box-body-value" style={{fontSize: "16px"}}>{controlLeverUnitOfMeasurement}</h2>
                    </div>
                </div>
            </div>
              
            </div>
          );
        }
      });  */
  };


/* ************************************************* New code ends here ********************************************************* */
    editDoubleClickHandler = (e) => {
        e.target.style.display = "none";
        e.target.nextElementSibling.style.display = "block";
    }

    reArrangePopUpClick = () => {

        if(this.state.reArrangePopUp === true){
            // do something
        }

        this.setState({
            reArrangePopUp : !this.state.reArrangePopUp
        })
    }

    addGoalSettingRow = (title) => {
        let prevGoal = [...this.state.goalSetting];
        let ind = prevGoal.map((goal)=>goal.title).indexOf(title);
        prevGoal[ind].description.push({
                    id: 0,
                    description: "Double Click to Edit"
                });
        this.setState({
            goalSetting : prevGoal
        })
    }    

    arrayGoalSettingHandler = (e) => {
        e.target.style.display = "none";
        e.target.nextElementSibling.style.display = "block";
    }

    goalSettingKeyDownHandler = (e) => {
        //console.log('-keyCode',e.key);
        if(e.key === 'Enter')
        {
            e.target.style.display = "none";
            e.target.previousElementSibling.style.display = "block";
        }
    }

    arrayGoalSettingHandlerEdit = (e,title, key) => {
        //alert('ok');
        let prevGoal = [...this.state.goalSetting];
        let ind = prevGoal.map((goal)=>goal.title).indexOf(title);
        prevGoal[ind].description[key].description = e.target.value;
        this.setState({
            goalSetting : prevGoal,
            modificationStarted:true
        })        
    }

    arrayGoalSettingBlurHandler = (e) => {
        e.target.style.display = "none";
        e.target.previousElementSibling.style.display = "block";
        
    }

    goalSettingDeleteRowHandler = (title,key) => {
        confirmAlert({
            title: 'Confirm to continue',
            message: 'Are you sure to delete this '+title+ '?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let prevGoal = [...this.state.goalSetting];
                        let ind = prevGoal.map((goal)=>goal.title).indexOf(title);
                        prevGoal[ind].description.splice(key,1);
                        this.setState({
                            goalSetting : prevGoal
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });     
    }    

    addRowsHandler = (e,rowId) => {
        let prevRows = [...this.state.categoryRows];
        let inp = e.target;
        let ind = prevRows.map((row)=>row.CategoryId).indexOf(rowId);
        if(ind === -1){
            prevRows.push({
                CategoryId : rowId,
                title : e.target.nextElementSibling.innerHTML,
                Kpi : []
            })
            inp.checked = true;
        }else{
            confirmAlert({
                title: 'Confirm to continue',
                message: 'Are you sure to delete '+e.target.nextElementSibling.innerHTML+' ?' ,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            prevRows.splice(ind,1);
                            inp.checked = false;
                            this.setState({
                                categoryRows : prevRows
                            })
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            inp.checked = true;
                        }
                    }
                ]
            });
        }
        
        this.setState({
            categoryRows : prevRows
        })
    }
    
    
    componentDidMount = () => {        
        this.sidebarAnimation.toggle();
        //this.props.fetchBalancedScorecardKpi(this.props.match.params.bscId?this.props.match.params.bscId:0);
        //this.props.fetchBalancedScorecardCategories(this.props.match.params.bscId?this.props.match.params.bscId:0);
        this.props.fetchAllKpiAgainstBalancedScorecards(this.props.match.params.bscId?this.props.match.params.bscId:0)
        this.props.fetchAllBalancedScorecards();
        if(this.state.bscId !== 0){
            this.fetchBsc(this.state.bscId);
            this.getCategoryNameKpiNameControlLevers(this.state.bscId);
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if(nextProps.match.params.bscId){
            if(this.state.bscId != nextProps.match.params.bscId){
                this.getCategoryNameKpiNameControlLevers(nextProps.match.params.bscId?nextProps.match.params.bscId:0);
                //this.props.fetchBalancedScorecardKpi(nextProps.match.params.bscId?nextProps.match.params.bscId:0);
                //this.props.fetchBalancedScorecardCategories(nextProps.match.params.bscId?nextProps.match.params.bscId:0);
                this.props.fetchAllKpiAgainstBalancedScorecards(nextProps.match.params.bscId?nextProps.match.params.bscId:0);
                this.props.fetchAllBalancedScorecards();
                this.setState({
                    color : "yellow",
                    ovalId : 1,
                    ovalShape : [],
                    goalSetting : [{
                        "id": 0,
                        "title": "Vision",
                        "description": "Double Tap to Edit."
                    },{
                        "id": 0,
                        "title": "Purpose",
                        "description": []
                    },{
                        "id": 0,
                        "title": "StrategicPriorities",
                        "description": []
                    },{
                        "id": 0,
                        "title": "StrategicResults",
                        "description": []
                    }],
                    bscId : nextProps.match.params.bscId,
                    bscName : "",
                    connections : [],
                    showNameValidation : false,
                    selectBscModalOpen : false
                })
                if(this.state.bscId !== 0){
                    this.fetchBsc(nextProps.match.params.bscId)
                }
            }
        }
        if(Object.keys(nextProps.kpiData).length) {
            this.setState({
                kpiData: nextProps.kpiData
            })
        }
    }
    fetchBsc = async (id) => {
        this.props.startLoader();
        let bsc = await axios.get(config.laravelBaseUrl + "getBscConfig/" +id,{
            headers: {
            "authorization": "Bearer " + CacheStorage.getItem("userToken")
            }
        }).then(result => {
            this.props.stopLoader();
            let bscData = result.data.data;
            this.setState({
                bscName : bscData.title,
                goalSetting : bscData.goalsetting,
                categoryRows : bscData.strategicobjective,
                showGoalSetting:true
            });    
            
          }).catch(error => {
            responseMessage("error", "Sorry something went wrong. Please reload the page", "");
            console.log(error);
          });
        
    }
    
    goalSettingChangeHandler = (e,title) => {
        //alert('vis')
        let goalSet = [...this.state.goalSetting];
        let ind = goalSet.map((goal)=>goal.title).indexOf(title);
        goalSet[ind].description = e.target.value
        this.setState({
            goalSetting : goalSet,
            modificationStarted:true
        })
    }
    GoalSettingBlurHandler = (e) => {
        e.target.style.display = "none";
        e.target.previousElementSibling.style.display = "block";
    }
    
    
    editTextHandler = (e) => {
        e.target.style.display = "none";
        e.target.nextElementSibling.style.display = "block";
    }
    
    balanceScorecardNameChangeHandler = (e) => {
        this.setState({
            bscName : e.target.value
        },()=>{
            if(this.state.bscName.trim() !== ""){
                this.setState({
                    showNameValidation : false
                })
            }
        })
    }

    toggleSelectBSCModal = () => {
        this.setState({
            selectBscModalOpen : !this.state.selectBscModalOpen
        })
    }
    
    addNewBSCClickHandler = () => {
        if(!this.props.match.params.bscId && this.state.bscId != 0){
            
            this.setState({
                color : "yellow",
                ovalId : 1,
                ovalShape : [],
                goalSetting : [{
                    "id": 0,
                    "title": "Vision",
                    "description": "Double Tap to Edit."
                },{
                    "id": 0,
                    "title": "Purpose",
                    "description": []
                },{
                    "id": 0,
                    "title": "StrategicPriorities",
                    "description": []
                },{
                    "id": 0,
                    "title": "StrategicResults",
                    "description": []
                }],
                bscId : 0,
                bscName : "",
                connections : [],
                showNameValidation : false,
                selectBscModalOpen : false
            })
        }
    }
    deleteBalancedScorecard = (bscId,bscName) => {
        confirmAlert({
            title: 'Confirm to continue',
            message: 'Are you sure you want to delete '+bscName+' ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        axios.get(config.laravelBaseUrl + 'deleteBsc/'+bscId,{
                            headers:{
                                authorization : "Bearer "+sessionStorage.getItem('userToken')
                            }
                        }).then(res=>{
                            if(this.props.match.params.bscId == bscId){
                                this.props.history.push('/balanced-scorecard')
                            }
                            this.props.fetchAllBalancedScorecards();
                            responseMessage('success',res.data.message)
                        }).catch(err=>{
                            responseMessage('error',err.response.data.message)
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }    

    changeDDChangeNotify(targetvalue){
        
    var e = document.getElementById("gbidd");
    var value = e.value;
    var text = e.options[e.selectedIndex].text;

        if (window.confirm("Unsaved changes. Changes made since last save will be lost. Do you want to continue?")) {
            window.location.href = this.state.bscId > 0 ? ''+targetvalue : '/balanced-scorecard/'+targetvalue
          } else {
            e.value = this.state.bscId;
            return false;
          }
    }
    
    render(){
        //console.log("this.props.strategicObjectives",this.props.strategicObjectives)
        return(
            <>
                <DashboardHeader />                
                <BalancedScorecardWrapper id="wrapper">
                <Prompt
            when={this.state.modificationStarted}
            message={() =>
            `Unsaved changes. Changes made since last save will be lost. Do you want to continue?`
            }
        />
                <DashboardSidebar></DashboardSidebar>
                    {/* <!-- Content Wrapper --> */}
                    <div id="content-wrapper" className="d-flex flex-column">
                        {/* <!-- Main Content --> */}
                        <div id="content">
                            <BalancedScorecardBreadcrumb />
                            {/* <!-- Begin Page Content --> */}
                            <div className="container-fluid container-dashboard">
                                <div className="row">
                                    <div className="dt-screen-main" style={{overflow:"hidden"}}>
                                        {/* <!-- Start Body Content --> */}
                                        <div className="dt-content-wrapper">
                                            <div className="content-wraper">
                                            <div className="container-fluid">
                                                {/* <!-- Balanced Scorecard Section --> */}
                                                    <div className="row balanced-version">
                                                    <div className="col-sm-12 col-md-12 col-lg-9 d-md-flex align-items-center pt-3">
                                                        <h2 style={{width:"38%"}}>Global Business Initiative</h2>
                                                        <select id="gbidd" className="form-control" style={{width:"43%"}} onChange={(e)=>(this.state.modificationStarted) ? this.changeDDChangeNotify(e.target.value): window.location.href = this.state.bscId > 0 ? ''+e.target.value : '/balanced-scorecard/'+e.target.value}>
                          <option value="">Select Global Business Initiative Name</option>
                          {this.props.allBsc.map(bsc=>{
                                return <option key={`bsc_${bsc.BalanceScoreCardId}`} value={bsc.BalanceScoreCardId} selected={this.state.bscId==bsc.BalanceScoreCardId ? true : false }>{bsc.BalanceScoreCardName}</option>
                              })}      
                        </select>
                                                        &nbsp; &nbsp; 
                                                        {
                                                      (this.state.showCardKpi && this.state.circular == 'N' && this.state.showGoalSetting) ?
                                                        <ul className="list-inline pager-list">
                                                        <li className="list-inline-item"><a href="javascript:void(0);" className="btn btn-back" style={{width:"120px", textTransform: "capitalize", marginTop:"17px"}} onClick={()=>this.setState({BalancedScorecardGoalSettingModal:!this.state.BalancedScorecardGoalSettingModal})}>Goal Setting</a> </li>
                                                        </ul>
                                                        :null }
                                                    </div>
                                                    
                                                    <div className="col-sm-12 col-md-12 col-lg-3 pt-3 text-right">
                                                        <ul className="list-inline pager-list">
                                                            {/* <li className="list-inline-item">
                                                                <a href="#" className="btn-download"><i className="fas fa-cloud-download-alt"></i> Download Report</a>
                                                            </li> */}
                                                            {
                                                      (this.state.showCardKpi && this.state.circular == 'N' && this.state.showGoalSetting) ?
                                                            <li className="list-inline-item"> {(this.state.circular == 'N' && this.state.modificationStarted ) ? <a href="javascript:void(0);" className="btn btn-back" style={{textTransform: "capitalize", marginTop:"17px"}} onClick={this.saveBalancedScorecardHandler.bind(this)}>Save</a>:<a href="javascript:void(0);" className="btn btn-back" style={{backgroundColor:"#aaabad", borderColor:"#bfc1c3", cursor:"default", textTransform: "capitalize", marginTop:"17px"}}>Save</a>} </li>
                                                            :null }
                                                            
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="balanced-details-row mt-3">
                                                <div className="balanced-col-wraper">
                                                <div className="row mb-2">
                                                <div className="col-sm-12 pl-0">
                                                {
                                                      this.state.showCardKpi ?
                                                        <h1 className="head-title">Business Target Outcome/KPIs</h1>
                                                    :'' 
                                                }
                                                </div>
                                                </div>
                                                <div className="card_details_kpi">
                                                    {
                                                      this.state.showCardKpi && this.props.match.params.bscId && this.props.strategicObjectives.map((m,ind)=>
                                                            <React.Fragment>
                                                            <div className="card_details_col">
                                                            <CardSettingKpi 
                                                            ind={ind}
                                                            KPIID={m.KPIID?m.KPIID:0}
                                                            heading={m.KPIName?m.KPIName:''} 
                                                            BusinessMetrics={m.BusinessMetrics?m.BusinessMetrics:'Not available'}
                                                            BSCCategory={m.BscCategory?m.BscCategory:'Not available'}
                                                            UnitOfMeasurement={m.UnitOfMeasurement?m.UnitOfMeasurement:'Not available'}
                                                            ExpectedTargetGrowthAmount={m.UnitOfMeasurement == 'percent' ? m.ExpectedTargetGrowthPercentage:m.ExpectedTargetGrowthAmount}                                                            
                                                            functionClick={() => console.log("clicked")}
                                                            categoryChartLegend={this.state.categoryChartLegend}
                                                            />
                                                            </div>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </div>
                                                </div>
                                                </div>
                                                <div className="row" style={{paddingLeft:"15px"}}>                                                
                                                { this.state.showGrantTaskPanel ? 
                                                <div className="col-sm-12" style={{padding:"0px"}}>
                                                    <div className="bg-white balanced-details-row1" style={{paddingTop:"0px"}}>
                                            <Gantt tasks={this.state.gantttasks} 
                                            viewMode="Month" 
                                            viewDate={new Date(new Date().getTime())}
                                            preStepsCount={1}
                                            columnWidth="450" 
                                            listCellWidth=""
                                            onDateChange={(e)=>this.handleTaskChange(e)}
                                            onClick={(e)=>this.handleGrantTaskClick(e)}
                                            TooltipContent="false"
                                            />
                                            </div>
                                                </div>                                               
                                            
                                            :null }

                                                { this.state.showDependencyPanel ?
                                                <div className="col-sm-12 bg-white balanced-details-row1 mt-3 mb-4" style={{paddingBottom:"20px"}}>                                                    
                                                    <BalancedScorecardDependencyTabular
                                                        categoryRows = {this.state.categoryRows}
                                                        categoryNameKpiNameControlLevers = {this.state.categoryNameKpiNameControlLevers}
                                                        changeSubKpiDependency = {this.changeSubKpiDependency.bind(this)}
                                                        markModificationStarted = {this.markModificationStarted.bind(this)}
                                                        ddoptions={this.state.subKpiDD}
                                                        changeSubKpiDependencyDropdown = {this.changeSubKpiDependencyDropdown.bind(this)}
                                                        categoryChartLegend={this.state.categoryChartLegend}
                                                        circular={this.state.circular}
                                                        latestDependencyIdtoCheckCircular={this.state.latestDependencyIdtoCheckCircular}
                                                    />
                                                </div> 
                                                : null } 

                                                </div>
                                                                                            
                                                
                                                { this.state.showDependencyPanel ?
                                                <div className="row">
                                                <div className="col-sm-9">
                                                
                                                </div>
                                                <div className="col-sm-3">
                                                <div style={{display:"flex", justifyContent:"flex-end", marginRight:"1px",marginTop:"10px",marginBottom:"30px"}}>
                                                <Link to={"/balanced-scorecard/report/"+this.state.bscId}><button style={{backgroundColor:"#0c33a5", padding:7, fontSize:"12px", width:"107px", height:"40px", marginRight:"10px"}} type="button" className="btn btn btn-primary">Generate Report</button></Link>
                    {(this.state.circular == 'N' && this.state.modificationStarted ) ? <button style={{backgroundColor:"#0c33a5", padding:7, fontSize:"12px", width:"107px", height:"40px"}} onClick={(e) => this.saveBalancedScorecardHandler(e)} type="button" className="btn btn btn-primary">Save</button>:<button style={{backgroundColor:"#6a6969", borderColor:"#6a6969", cursor:"default", padding:7, fontSize:"12px", width:"107px", height:"40px"}} type="button" disabled className="btn btn btn-primary">Save</button>}
                    
                  </div> 
                                                </div>
                                                </div>
                                                : null } 
                                            {/* <!-- End Balanced Scorecard Section --> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Body Content --> */}
                            </div>
                        </div>
                    {/* <!-- /End container-fluid --> */}
                    </div>
                {/* <!-- End of Main Content --> */}
                </div>
                {/* <!-- End of Content Wrapper --> */}

                <ModalPopup isOpen={this.state.showcontrolLeversModal} toggle={this.toggleshowcontrolLeversModal.bind(this)} title="Control Levers/Sub KPIs" bodyHeight={"255px"} >
    <div className="table-responsive popup-table">
    <table className="table mb-0">
            <thead>
                <tr>
                    <th style={{ textAlign:"left", backgroundColor:"#ffdf83"}}>{this.state.controlLeversTitleModal}</th>                    
                </tr>
            </thead>
            <tbody>
            <tr style={{ border:"0px"}}>
            <td style={{ border:"0px"}}>Expected Gain</td>
            </tr>
            <tr style={{ border:"0px"}}>
            <td style={{textAlign:"left", border:"0px", paddingTop:"0px"}}>{this.state.controlLeverExpectedGainModal}</td>
            </tr>
            <tr style={{ border:"0px"}}>
            <td style={{ border:"0px"}}>Unit Of Measurement</td>
            </tr>
            <tr style={{ border:"0px"}}>
            <td style={{textAlign:"left", border:"0px", paddingTop:"0px"}}>{this.state.controlLeverUnitOfMeasurementModal}</td>
            </tr>
            </tbody>
        </table> 
           
    </div>
    <div className="modal-footer">
    </div> 
</ModalPopup> 
                <ModalPopup isOpen={this.state.selectBscModalOpen} toggle={this.toggleSelectBSCModal.bind(this)} title="Global Business Initiative" bodyHeight={true} >
                    <div className="table-responsive popup-table">
                        <table className="table table-striped mb-0">
                            <thead>
                                <tr>
                                    <th style={{width:"15%"}}>Sr No.</th>
                                    <th>Global Business Initiative Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.allBsc.map((bsc,ind)=>{
                                    return(
                                        <tr key={bsc.BalanceScoreCardId}>
                                            <td>{ind+1}</td>
                                            <td className="d-flex" style={{justifyContent:"space-between"}}><Link to={"/balanced-scorecard/"+bsc.BalanceScoreCardId}>{bsc.BalanceScoreCardName} </Link> <i className="fa fa-times text-danger" onClick={()=>this.deleteBalancedScorecard(bsc.BalanceScoreCardId,bsc.BalanceScoreCardName)}></i> </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>    
                    </div>
                    <div className="modal-footer">
                    </div> 
                </ModalPopup>
                <ModalPopup style={{maxWidth:"95%"}} isOpen={this.state.BalancedScorecardGoalSettingModal} toggle={()=>this.setState({BalancedScorecardGoalSettingModal:!this.state.BalancedScorecardGoalSettingModal})} bodyHeight={true} bodyHeightValue={350} >
                    <div style={{marginTop:"-45px", padding:"5px 30px"}}>
                <BalancedScorecardWrapper id="wrapper" style={{display:'block',marginBottom:"0px"}}>
                <BalancedScorecardGoalSetting
                editDoubleClickHandler={this.editDoubleClickHandler.bind(this)}
                GoalSettingBlurHandler={this.GoalSettingBlurHandler.bind(this)}
                goalSettingChangeHandler={this.goalSettingChangeHandler.bind(this)}
                goalSettingKeyDownHandler={this.goalSettingKeyDownHandler.bind(this)}
                GoalSetting={this.state.goalSetting}
                addGoalSettingRow={this.addGoalSettingRow.bind(this)}
                arrayGoalSettingHandler={this.arrayGoalSettingHandler.bind(this)}
                arrayGoalSettingHandlerEdit={this.arrayGoalSettingHandlerEdit.bind(this)}
                arrayGoalSettingBlurHandler={this.arrayGoalSettingBlurHandler.bind(this)}
                goalSettingDeleteRowHandler={this.goalSettingDeleteRowHandler.bind(this)}
                    />
                
                </BalancedScorecardWrapper>
                </div>
                </ModalPopup>
                <ModalPopup isOpen={this.state.reArrangePopUp} toggle={this.reArrangePopUpClick.bind(this)} title="Re-Arrange Strategic Objectives" bodyHeight={true} >
                    <div className="table-responsive popup-table">
                        <table className="table table-striped mb-0">
                            <thead>
                                <tr>
                                    <th>Strategic Objectives</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.categoryRows.map((catRow,ind)=>{
                                    return(
                                        <tr key={`re_arrange_row_${catRow.CategoryId}`}>
                                            <td className="d-flex" style={{justifyContent:"space-between"}}>
                                                <span>
                                                    {catRow.title} 
                                                </span> 
                                                <span>
                                                    <i class="fas fa-arrow-up" onClick={()=>this.moveCategoryRow('up',ind)}></i>
                                                    &nbsp;&nbsp;
                                                    <i class="fas fa-arrow-down" onClick={()=>this.moveCategoryRow('down',ind)}></i>
                                                </span> 
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>    
                    </div>
                </ModalPopup>
                </BalancedScorecardWrapper>
                <AsideComponent />
                
                <FooterComponent />
            </>
        );
    }
}

const mapStateToProps = state => ({
    kpiData : state.balancedScorecard.kpiData,
    categories : state.balancedScorecard.categories,
    allBsc : state.balancedScorecard.allBsc,
    strategicObjectives:state.balancedScorecard.strategicObjectives
})

export default connect(mapStateToProps, {fetchBalancedScorecardKpi,fetchBalancedScorecardCategories, fetchAllBalancedScorecards,fetchAllKpiAgainstBalancedScorecards, startLoader, stopLoader})(BalancedScorecard);