import React from 'react'
import styles from '../ShowDataTable.module.css';
const TableCell = ({ value, isEditing, onChange, onBlur }) => (
  isEditing ? (
    <input
      type="text"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      className={styles.input}
      autoFocus
    />
  ) : (
    <span>{value}</span>
  )
);

export default TableCell;