import React from "react";
import styles from "./ApiSection.module.css"; // Ensure this file exists
import { useHistory } from "react-router-dom"; // Ensure react-router-dom is properly installed
import ShowDataTable from "../ShowDataTable/ShowDataTable"; // Ensure ShowDataTable exists and props match

const headers = [
  "#",
  "Title",
  "Category",
  "Status",
  "Actions",
  "Last updated by",
  "Last updated on",
];

const ApplyClass = Array(headers.length).fill(0); // Ensures dynamic consistency with headers

const data = [
  ["1", "HFC - 23", "14800", "5000", "2000", "500", "111000000"],
  ["2", "HFC - 32", "675", "400", "200", "200", "540000"],
]; // Ensure data aligns with headers

const ApiSection = () => {
  const navigate = useHistory();

  const handleButtonClick = () => {
    navigate.push("/data-management/createRequest?section=3"); // Ensure the route exists in your app
  };

  return (
    <div className={styles.fileUploadCont}>
      <button className={styles.button} onClick={handleButtonClick}>
        <svg
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 3V18H12V3H9ZM12 5L16 18L19 17L15 4L12 5ZM5 5V18H8V5H5ZM3 19V21H21V19H3Z"
            fill="#1232C3"
          />
        </svg>
        New API Request
      </button>
      <ShowDataTable
        showSorting={true}
        showMatching={false}
        showTitle={false}
        showSearchFilter={true}
        headerData={headers}
        extractedData={data}
        isDataEditable={false}
        isTemplateSection={false}
        ApplyClass={ApplyClass}
      />
    </div>
  );
};

export default ApiSection;
