import React from 'react';
import { useContext, useState } from "react";
import { Context } from "../contexts/Store";
import styles from "./playground.module.css";

const RightPanel = () => {
  const { dispatch } = useContext(Context);
  const [panelOpen, setPanelOpen] = useState("");

  const showPanel = (e) => {
    setPanelOpen(e);
    dispatch({ type: "SET_INFOPANEL", payload: true });
  };

  const closePanel = (e) => {
    setPanelOpen("");
    dispatch({ type: "SET_INFOPANEL", payload: false });
  };

  return (
    <>
      <ul>
        <li
          className={
            panelOpen === "Documentation"
              ? styles.right_panel_active_link
              : "list_tag"
          }
          onClick={(e) => showPanel("Documentation")}
        >
          <i
            className="bi bi-code-slash"
            style={{ fontSize: "12px", paddingBottom: "7px" }}
          ></i>
        </li>
        <li
          className={
            panelOpen === "Comments"
              ? styles.right_panel_active_link
              : "list_tag"
          }
          onClick={(e) => showPanel("Comments")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="11"
            fill="currentColor"
            className="bi bi-arrow-down-up"
            viewBox="0 0 16 16"
            transform="rotate(45)"
          >
            <path
              fillRule="evenodd"
              d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5"
            />
          </svg>
        </li>
        <li
          className={
            panelOpen === "Code snippet"
              ? styles.right_panel_active_link
              : "list_tag"
          }
          onClick={(e) => showPanel("Code snippet")}
        >
          <i className="feather-code"></i>
        </li>
        <li
          className={
            panelOpen === "Request details"
              ? styles.right_panel_active_link
              : ""
          }
          onClick={(e) => showPanel("Request details")}
        >
          <i className="feather-info"></i>
        </li>
      </ul>
      {panelOpen !== "" && (
        <div className={styles.info_drawer}>
          <div className={styles.info_drawer_header}>
            <span>{panelOpen}</span>
            <button type="button" onClick={closePanel}>
              &times;
            </button>
          </div>
          <div className={styles.info_drawer_scroll}>
            <div className={styles.blank_drawer}>Not Available</div>
          </div>
        </div>
      )}
    </>
  );
};

export default RightPanel;

