import React from 'react';
const StoreReducer = (state, action) => {
  switch (action.type) {
    case "SET_OVERVIEW":
      return {
        ...state,
        overviewTab: action.payload,
      };
    case "CLOSE_OVERVIEW":
      return {
        ...state,
        overviewTab: "",
      };
    case "SET_SIDEDRAWER":
      return {
        ...state,
        sideDrawerOpened: action.payload,
      };
    case "SET_SIDEDRAWER_TAB":
      return {
        ...state,
        sideDrawerTab: action.payload,
      };
    case "SET_RESPONSE_UI":
      return {
        ...state,
        tabs: state.tabs.map((tab) =>
          tab.id === state.activeTabId
            ? { ...tab, responseUI: action.payload }
            : tab
        ),
      }
    case "SET_INFOPANEL":
      return {
        ...state,
        infoPanelOpened: action.payload,
      };
    case "SET_SPLIT_VIEW":
      return {
        ...state,
        splitView: action.payload,
      };
    case "SET_RESPONSE_PANEL":
      return {
        ...state,
        tabs: state.tabs.map((tab) =>
          tab.id === state.activeTabId
            ? { ...tab, responsePanelMinimized: action.payload }
            : tab
        ),
      };
    case "SET_METHOD":
      return {
        ...state,
        tabs: state.tabs.map((tab) =>
          tab.id === state.activeTabId
            ? { ...tab, method: action.payload }
            : tab
          )
      }
    case "SET_URL":
      return {
        ...state,
        tabs: state.tabs.map((tab) =>
          tab.id === state.activeTabId
            ? { ...tab, url: action.payload,name:action.payload }
            : tab
        ),
      };
    case "SET_QPARAMS":
      return {
        ...state,
        tabs: state.tabs.map((tab) =>
          tab.id === state.activeTabId
            ? { ...tab, params: action.payload }
            : tab
        ),
      };
    case "SET_AUTH":
      return {
        ...state,
        tabs: state.tabs.map((tab) =>
          tab.id === state.activeTabId
            ? { ...tab, auth: action.payload }
            : tab
        ),
      };
    case "SET_AUTH_LOCATION":
      return {
        ...state,
        tabs: state.tabs.map((tab) =>
          tab.id === state.activeTabId
            ? { ...tab, authLocation: action.payload }
            : tab
        ),
      };
    case "SET_REQUEST_HEADERS":
      return {
        ...state,
        tabs: state.tabs.map((tab) =>
          tab.id === state.activeTabId
            ? { ...tab, requestHeaders: action.payload }
            : tab
        ),
      }
    case "SET_AUTH_HEADER":
      return {
        ...state,
        tabs: state.tabs.map((tab) =>
          tab.id === state.activeTabId
            ? { ...tab, authHeader: action.payload }
            : tab
        ),
      };
    case "SET_PAYLOAD":
      return {
        ...state,
        tabs: state.tabs.map((tab) =>
          tab.id === state.activeTabId
            ? { ...tab, payload: action.payload }
            : tab
        ),
      };
    case "SET_FORM_SUBMIT":
      return {
        ...state,
        tabs: state.tabs.map((tab) =>
          tab.id === state.activeTabId
            ? { ...tab, formSubmitted: true,method:action.payload.method,url:action.payload.url,params:action.payload.params,payload:action.payload.payload }
            : tab
        ),
      };
    case "CANCEL_FORM_SUBMIT":
      return {
        ...state,
        tabs: state.tabs.map((tab) =>
          tab.id === state.activeTabId
            ? { ...tab, url: "", params: "", payload: "", formSubmitted: false }
            : tab
        ),
      };
    case "SET_API_RESPONSE":
      return {
        ...state,
        tabs: state.tabs.map((tab) =>
          tab.id === state.activeTabId
            ? { ...tab, apiResponse: action.payload, apiError: null, formSubmitted: false }
            : tab
        ),
      };
    case "SET_API_ERROR":
      return {
        ...state,
        tabs: state.tabs.map((tab) =>
          tab.id === state.activeTabId
            ? { ...tab, apiError: action.payload, apiResponse: {}, formSubmitted: false }
            : tab
        ),
      };
    case "RESET_FORM":
      return {
        ...state,
        tabs: state.tabs.map((tab) =>
          tab.id === state.activeTabId
            ? {
              ...tab,
              method: "GET",
              url: "",
              params: "",
              payload: "",
              headers: [],
              auth: "",
              authHeader: "",
              authLocation: "header",
              requestHeaders: [],
              formSubmitted: false,
              responseUI: false,
              apiResponse: {},
              apiError: null,
            }
            : tab
        ),
      };
    case "SET_ACTIVE_TAB":
      return {
        ...state,
        activeTabId: action.payload,
      };

    case "ADD_TAB":
      const newTab = {
        id: action.payload.id,
        name: "no_name_request",
        method: "GET",
        url: "",
        params: "",
        payload: "",
        headers: [],
        responsePanelMinimized: false,
        auth: "",
        authHeader: "",
        authLocation: "header",
        requestHeaders: [],
        formSubmitted: false,
        responseUI: false,
        apiResponse: {},
        apiError: null,
      };
      return {
        ...state,
        tabs: [...state.tabs, newTab],
        activeTabId: newTab.id,
      };

    case "REMOVE_TAB": {
      return {
        ...state,
        tabs: action.payload,
      };
    }

    case "UPDATE_TAB":
      return {
        ...state,
        tabs: state.tabs.map((tab) =>
          tab.id === action.payload.id ? { ...tab, ...action.payload } : tab
        ),
      };

    default:
      return state;
  }
};

export default StoreReducer;
