import React from 'react';
import { useContext, useState } from "react";
import { Context } from "../contexts/Store";
import styles from "./playground.module.css";

const QueryParamsTable = () => {
  const { state, dispatch } = useContext(Context);
  const [authHeader] = useState(() => {
    if (state.tabs[state.activeTabId - 1]?.authLocation === "qp") {
      const header = state.tabs[state.activeTabId - 1]?.authHeader.split(":");
      if (header.length === 2) {
        return { keyName: header[0], value: header[1], selected: true };
      }
    }
    return null;
  });

  const [inputList, setInputList] = useState(() => {
    if (state.tabs[state.activeTabId - 1]?.params) {
      const params = state.tabs[state.activeTabId - 1]?.params.split("&");
      return params.map((p) => ({
        keyName: p.split("=")[0],
        value: p.split("=")[1],
        selected: true,
      }));
    } else {
      return [{ keyName: "", value: "", selected: true }];
    }
  });

  const handleInputChange = (e, index) => {
    const { name, value, checked } = e.target;
    const list = [...inputList];
    list[index][name] = name === "selected" ? checked : value;
    setInputList(list);
    getParams(list);

    // Check if this is the last row and either keyName or value is not empty
    if (
      index === list.length - 1 &&
      (list[index].keyName !== "" || list[index].value !== "")
    ) {
      handleAddClick();
    }
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(() => list);
    getParams(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { keyName: "", value: "", selected: true }]);
  };

  const getParams = (list) => {
    const params = list
      .filter((qp) => qp.selected && qp.keyName !== "")
      .map((e) => `${e.keyName}=${e.value}`)
      .join("&");
    dispatch({ type: "SET_QPARAMS", payload: params });
  };

  return (
    <>
      <div
        style={{
          padding: "10px",
          paddingLeft: "12px",
          fontFamily:
            "Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', Helvetica, Arial, sans-serif",
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "normal",
          color: "black",
        }}
      >
        Query Params
      </div>
      <div
        className={
          state.tabs[state.activeTabId - 1]?.responsePanelMinimized ||
          state.splitView === "V"
            ? state.splitView === "V"
              ? styles.payload_wrapper_full_v
              : styles.payload_wrapper_full
            : styles.payload_wrapper
        }
        style={{ paddingLeft: "5px" }}
      >
        <table
          className={
            state.splitView === "H" ? styles.qp_table : styles.qp_table_small
          }
        >
          <thead>
            <tr>
              <th></th>
              <th style={{ paddingLeft: "12px" }}>
                <b>Key</b>
              </th>
              <th style={{ paddingLeft: "12px" }}>
                <b>Value</b>
              </th>
              <th style={{ paddingLeft: "12px" }}>
                <span>
                  <b> Description</b>
                </span>
                <span className={styles.qp_options}>
                  <span>
                    <i class="bi bi-three-dots"></i>
                  </span>
                  <span>Bulk Edit</span>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {authHeader && (
              <tr>
                <td>
                  <input type="checkbox" name="selected" checked disabled />
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="Key"
                    name="keyName"
                    value={authHeader.keyName}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="Value"
                    name="value"
                    value={authHeader.value}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value="Added automatically from authorization."
                    placeholder="Description"
                    disabled
                  />
                </td>
              </tr>
            )}
            {inputList.map((x, i) => (
              <tr key={`qp-input-row-${i}`}>
                <td>
                  <input
                    type="checkbox"
                    name="selected"
                    checked={x.selected}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="Key"
                    name="keyName"
                    value={x.keyName}
                    onChange={(e) => handleInputChange(e, i)}
                    spellCheck={false}
                    autoComplete="off"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="Value"
                    name="value"
                    value={x.value}
                    onChange={(e) => handleInputChange(e, i)}
                    spellCheck={false}
                    autoComplete="off"
                  />
                </td>
                <td>
                  <input type="text" placeholder="Description" />
                  {inputList.length !== 1 && (
                    <span onClick={() => handleRemoveClick(i)}>&times;</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default QueryParamsTable;

