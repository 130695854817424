import React from 'react'
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useState } from "react";
import styles from "./ShowDataTable.module.css";
import TableRow from "./TableRow/TableRow";
import TableHeader from "./TableHeader/TableHeader";
import WarningModal from "../Template/WarningModal/WarningModal";
import SaveCancelButton from "../SaveCancelButton/SaveCancelButton";
import SearchFilter from "../SearchFilterBar/SearchFilterBar";
import ProgressBar from "./ProgressBar/ProgressBar";
import Store from "../APIScreen/contexts/Store";

const ShowDataTable = ({
  isHomeSection = false,
  templateHeaders = [],
  showBackButton = false,
  showMappingTip = false,
  showMatching,
  showSorting,
  isMappingScreen = false,
  extraInfo,
  fileInfo,
  showSaveCancelButton = false,
  currentStep = null,
  showTitle,
  extractedData,
  headerData,
  isDataEditable,
  isTemplateSection,
  setSelectedTemplateId,
  onDelete,
  ApplyClass,
  showSearchFilter = false,
}) => {
  //isFileUploaded -> 1 for without template, 2 for with template

  const handleAddClass = useCallback(
    (index) => {
      if (ApplyClass[index] === -2) return styles.whiteBg;
      if (ApplyClass[index] === 0) return styles.blackBg;
      if (ApplyClass[index] === 1) return styles.greenBg;
      if (ApplyClass[index] === -1) return styles.redBg;
      if (ApplyClass[index] === 2) return styles.greyBg;
    },
    [ApplyClass],
  );

  const [sortColumn, setSortColumn] = useState(1);

  // console.log(extractedData)

  const [state, setState] = useState({
    selectedOptions: {},
    tableTitle: fileInfo?.fileName,
    headerData: headerData || [],
    showWarning: false,
  });

  const [searchText, setSearchText] = useState(null);
  const [filteredExtractedData, setFilteredExtractedData] = useState(
    extractedData || [],
  );

  const sortData = (data) => {
    const sortedData = [...data].sort((a, b) => {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];

      if (valueA === undefined || valueB === undefined) return 0;

      if (typeof valueA === "string" && typeof valueB === "string") {
        return valueA.localeCompare(valueB);
      }
      if (typeof valueA === "number" && typeof valueB === "number") {
        return valueA - valueB;
      }
      return 0;
    });

    return sortedData;
  };

  useEffect(() => {
    const getFilteredAndSortedData = () => {
      const dataToFilter = searchText
        ? extractedData.filter((row) =>
            row[1]?.toLowerCase().includes(searchText.toLowerCase()),
          )
        : extractedData;
      return sortData(dataToFilter);
    };

    setFilteredExtractedData(getFilteredAndSortedData());
  }, [searchText, extractedData]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      tableTitle: showTitle ? prevState?.tableTitle : fileInfo?.fileName || "",
      tableData: extractedData || [],
      headerData: headerData || [],
    }));
  }, [extractedData, headerData, showTitle, fileInfo]);

  const [editingCell, setEditingCell] = useState({
    rowIndex: null,
    cellIndex: null,
  });
  const [dropdownState, setDropdownState] = useState({
    showOptions: { rowIndex: null, cellIndex: null },
    position: { top: 0, left: 0 },
  });

  const handleDropdownClick = useCallback((rowIndex, cellIndex, event) => {
    const { pageX, pageY } = event;
    setDropdownState((prev) => ({
      showOptions: {
        rowIndex: prev.showOptions.rowIndex === rowIndex ? null : rowIndex,
        cellIndex: prev.showOptions.cellIndex === cellIndex ? null : cellIndex,
      },
      position: {
        top: pageY, // Use pageY to include scroll offset
        left: pageX, // Use pageX to include scroll offset
      },
    }));
  }, []);

  const handleClose = () => {
    setDropdownState({
      showOptions: { rowIndex: null, cellIndex: null },
      position: { top: 0, left: 0 },
    });
  };

  const handleCellEdit = useCallback((rowIndex, cellIndex, event) => {
    setState((prev) => {
      const newData = [...prev.tableData];
      newData[rowIndex][cellIndex] = event.target.value;
      return { ...prev, tableData: newData };
    });
  }, []);

  const handleCellClick = useCallback(
    (rowIndex, cellIndex) => {
      if (isDataEditable) {
        setEditingCell({ rowIndex, cellIndex });
      }
    },
    [isDataEditable],
  );

  const handleRowEdit = useCallback(
    (rowIndex) => {
      if (isTemplateSection || isHomeSection) {
        setSelectedTemplateId(filteredExtractedData[rowIndex][0]);
        return;
      }
      setState((prev) => ({
        ...prev,
        editingRows: {
          ...prev.editingRows,
          [rowIndex]: !prev.editingRows[rowIndex],
        },
      }));
    },
    [
      isTemplateSection,
      setSelectedTemplateId,
      isHomeSection,
      filteredExtractedData,
    ],
  );

  const handleRemoveRow = useCallback(
    (templateID) => {
      onDelete(templateID); // Adjust for the header row
      setDropdownState({
        showOptions: { rowIndex: null, cellIndex: null },
        position: { top: 0, left: 0 },
      });
    },
    [onDelete],
  );

  const handleWarningConfirm = useCallback(() => {
    setState((prev) => ({
      ...prev,
      tableData: prev.tableData.filter(
        (_, index) => index !== prev.rowToRemove,
      ),
      showWarning: false,
      rowToRemove: null,
    }));
  }, []);

  const handleViewAndManageTemplate = useCallback(() => {
    setDropdownState((prev) => ({
      ...prev,
      showOptions: { rowIndex: null, cellIndex: null },
    }));
  }, [setDropdownState]);

  useEffect(() => {
    const sortedData = sortData(filteredExtractedData);
    setFilteredExtractedData(sortedData);
  }, [sortColumn]);

  return (
    <div className={styles.container}>
      {showBackButton ? (
        <button className={styles.backButton}>
          <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 1.42L3.42 6L8 10.59L6.59 12L0.59 6L6.59 1.23266e-07L8 1.42Z"
              fill="#1232C3"
            />
          </svg>
          Back
        </button>
      ) : null}

      {currentStep ? <ProgressBar currentStep={currentStep} /> : null}

      {state.showWarning && (
        <WarningModal
          onSave={handleWarningConfirm}
          onCancel={() => setState((prev) => ({ ...prev, showWarning: false }))}
        />
      )}
      {showSearchFilter && <SearchFilter setSearchText={setSearchText} />}

      {showTitle ? (
        <div className={styles.titleWrapper}>
          <div className={styles.fileInfo}>
            <p>
              {fileInfo?.fileName} uploaded
              <br />
              <b>{fileInfo?.fileSize} KB</b>
            </p>
            <p>
              Previewing {fileInfo?.rows} of {fileInfo?.totRows} rows
            </p>
          </div>
        </div>
      ) : null}

      {isMappingScreen ? (
        <div className={styles.extraInfo}>
          <b>
            <p>{extraInfo?.title}</p>
          </b>
          <div className={styles.extraInfoTitleBar}>
            <b>
              <p>{extraInfo?.typeName}</p>
            </b>
            <p>{extraInfo?.fileName}</p>
          </div>
        </div>
      ) : null}

      {isTemplateSection
        ? !filteredExtractedData?.length && (
            <div className={styles.noTemplateWarn}>
              <i className="bi bi-exclamation-circle"></i>
              <p>
                No template exists. Please upload the template you have created
                for a data category
              </p>
            </div>
          )
        : null}

      <div className={styles.tableResponsive}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.tableHeaderCell}>
                <div
                  className={`${handleAddClass(0)} ${styles.header}`}
                  style={{
                    borderTopLeftRadius: "0.5rem",
                    borderBottomLeftRadius: "0.5rem",
                  }}
                >
                  <i className="bi bi-list"></i>
                </div>
              </th>
              {state?.headerData?.map((header, index) => (
                <TableHeader
                  key={index}
                  header={header}
                  index={index}
                  selectedOptions={state.selectedOptions}
                  showMatching={showMatching}
                  showSorting={showSorting}
                  templateHeaders={templateHeaders}
                  sortColumn={sortColumn}
                  setSortColumn={setSortColumn}
                  handleDropdownChange={(index, event) =>
                    setState((prev) => ({
                      ...prev,
                      selectedOptions: {
                        ...prev.selectedOptions,
                        [index]: event.target.value,
                      },
                    }))
                  }
                  handleAddClass={() => handleAddClass(index)}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredExtractedData?.map((row, rowIndex) => (
              <tr key={rowIndex + 1} className={styles.tableRow}>
                <TableRow
                  row={row}
                  rowIndex={rowIndex}
                  editingCell={editingCell}
                  dropdownState={dropdownState}
                  handleDropdownClick={handleDropdownClick}
                  handleViewAndManageTemplate={handleViewAndManageTemplate}
                  handleRemoveRow={handleRemoveRow}
                  handleCellClick={handleCellClick}
                  handleCellEdit={handleCellEdit}
                  handleRowEdit={handleRowEdit}
                  handleClose={handleClose}
                  isHomeSection={isHomeSection}
                  isTemplateSection={isTemplateSection}
                />
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showMappingTip ? (
        <p className={styles.mappingTip}>
          <b>Tip :</b> Verify & map the ingested data table header with the
          template header.
        </p>
      ) : null}

      {showSaveCancelButton ? (
        <SaveCancelButton isMappingScreen={isMappingScreen} />
      ) : null}
    </div>
  );
};

export default ShowDataTable;
