import React from 'react'
import { useEffect, useState } from "react";
import { Route, Switch, Link, useLocation } from 'react-router-dom';
import ShowDataTable from "./ShowDataTable/ShowDataTable";
import TemplateComponent from "./Template/TemplatePage";
import CreateRequest from "./APIScreen/CreateRequest.js"
import TemplateView from "./Template/TemplateView/TemplateView";
import DashboardHeader from "../includes/dashboardHeader/DashboardHeader.js";
import DashboardSidebar from "../includes/dashboardSidebar/DashboardSidebar.js";
import FooterComponent from "../includes/dashboardFooter/FooterComponent.jsx";
import styles from "./DataManagement.module.css";
import FileUpload from "./FileUpload/FileUpload";
import UploadEvidence from "./UploadEvidence/UploadEvidence";
import ListEvidence from "./ListEvidence/ListEvidence";
import ApiSection from "./APISection/ApiSection";

const normalHeaders = [
  "#",
  "Title",
  "Category",
  "Status",
  "Actions",
  "Last updated by",
  "Last updated on",
];

const mappingScreenFileHeader = [
  "#",
  "Gas Type",
  "Gas",
  "Inventory Change",
  "Retained Amount",
  "Capacity Change",
  "CO2 Equivalent Emission",
];
const mappingScreenTemplateHeader = [
  "#",
  "Gas Type",
  "Gas",
  "Inventory Change",
  "Transferred Amount",
  "Capacity Change",
  "CO2 Equivalent Emission",
];

const ApplyClass = [-2, 1, 1, -1, -1, 1, 1];

const tempTemplateExtraInfo = {
  title: "Selected Template Headers",
  typeName: "Template Name",
  fileName: "Template 1",
};

const tempFileExtraInfo = {
  title: "Ingested Data Table Headers",
  typeName: "Table Name",
  fileName: "Gas CO2 Equivalent Emissions.csv",
};

const fileInfo = {
  fileName: "Gas CO2 Equivalent Emissions.csv",
  rows: 2,
  totRows: 20,
  fileSize: 300,
};

const data = [
  ["HFC - 23", "14800", "5000", "2000", "500", "111000000"],
  ["HFC - 32", "675", "400", "200", "200", "540000"],
];

const userID = 2;

const TABS = [
  { id: 1, path: "/data-management/", label: "Upload Files" },
  { id: 2, path: `/data-management/templates?section=2&userID=${userID}`, label: "Templates" },
  { id: 3, path: "/data-management/apis?section=3", label: "API Integration" },
];

const applyClassWithoutTemplate = [2, 2, 2, 2, 2, 2, 2];

const DataManagement = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useState(new URLSearchParams(location.search));
  const [select, setSelect] = useState(() => parseInt(searchParams.get("section") || 1));
  const [applyClass, setApplyClass] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [headerData, setHeaderData] = useState(normalHeaders);
  const [extractedData, setExtractedData] = useState(data);

  const handleClick = (opt) => {
    setSelect(opt);
    setSearchParams({ section: opt });
  };

  useEffect(() => {
    if (select == 1) {
      const elementsCount = normalHeaders?.length || 0;
      setApplyClass(Array(elementsCount).fill(0));
      setHeaderData(normalHeaders);
    } else if (select == 2) {
      const elementsCount = headerData?.length || 0;
      setApplyClass(Array(elementsCount).fill(0));
      setHeaderData(normalHeaders);
    } else if (select == 3) {
      setHeaderData(normalHeaders);
      setExtractedData(data);
      const elementsCount = normalHeaders?.length || 0;
      setApplyClass(Array(elementsCount).fill(0));
    }
  }, [select]);


  return [
    <DashboardHeader key="header"></DashboardHeader>,
    <div id="wrapper" key="body-wrapper">
      <DashboardSidebar></DashboardSidebar>
      <div className={styles["project-data"]}>
        <h2>Project Data</h2>

        <div className={styles.tabs}>
          {TABS.map(tab => (
            <Link key={tab.id} to={tab.path} className={styles.tab}>
              <button
                className={select === tab.id ? styles.active : undefined}
                onClick={() => handleClick(tab.id)}
              >
                {tab.label}
              </button>
            </Link>
          ))}
        </div>

        <div className={styles["upload-section"]}>

          <Switch>
            <Route
              exact
              path="/data-management"
              render={() => (
                <>
                  <FileUpload />
                  <ShowDataTable
                    // Configuration flags
                    showBackButton={false}
                    showSaveCancelButton={false}
                    showSaveButton={false}
                    showSorting={true}
                    showMatching={false}
                    showTitle={false}
                    showSearchFilter={false}
                    showMappingTip={false}
                    // Step & state management
                    currentStep={0}
                    isFileUploaded={1}
                    isDataEditable={false}
                    isTemplateSection={false}
                    isHomeSection={true}
                    // Data
                    fileInfo={fileInfo}
                    extraInfo={tempFileExtraInfo}
                    headerData={normalHeaders}
                    extractedData={extractedData}
                    // Styling
                    ApplyClass={applyClass}
                  />
                </>
              )}
            />

            <Route
              exact
              path="/data-management/dataVerify"
              render={() => (
                <ShowDataTable
                  // Configuration flags
                  showBackButton={true}
                  showSaveCancelButton={true}
                  showSaveButton={true}
                  showSorting={true}
                  showMatching={false}
                  showTitle={true}
                  showSearchFilter={false}
                  showMappingTip={false}
                  // Step & state management
                  currentStep={2}
                  isFileUploaded={1}
                  isDataEditable={true}
                  isTemplateSection={false}
                  // Data
                  fileInfo={fileInfo}
                  extraInfo={tempFileExtraInfo}
                  headerData={mappingScreenTemplateHeader}
                  extractedData={data}
                  // Styling
                  ApplyClass={applyClassWithoutTemplate}
                />
              )}
            />

            <Route
              exact
              path="/data-management/fileUploadWithTemplate"
              render={() => (
                <>
                  <ShowDataTable
                    // Configuration flags
                    showBackButton={true}
                    showSorting={false}
                    showMatching={true}
                    showTitle={true}
                    showSearchFilter={false}
                    showMappingTip={false}
                    // Step & state management
                    currentStep={1}
                    isFileUploaded={2}
                    isDataEditable={false}
                    isTemplateSection={false}
                    isMappingScreen={true}
                    // Data
                    templateHeaders={mappingScreenTemplateHeader}
                    fileInfo={fileInfo}
                    extraInfo={tempFileExtraInfo}
                    headerData={mappingScreenFileHeader}
                    extractedData={data}
                    // Styling
                    ApplyClass={applyClass}
                  />
                  <hr className={styles.mapLine} />
                  <ShowDataTable
                    // Configuration flags
                    showSaveCancelButton={true}
                    showSaveButton={true}
                    showSorting={false}
                    showMatching={false}
                    showTitle={false}
                    showSearchFilter={false}
                    showMappingTip={true}
                    // Step & state management
                    isFileUploaded={2}
                    isDataEditable={false}
                    isTemplateSection={false}
                    isMappingScreen={true}
                    // Data
                    extraInfo={tempTemplateExtraInfo}
                    headerData={mappingScreenTemplateHeader}
                    // Styling
                    ApplyClass={applyClass}
                  />
                </>
              )}
            />

            <Route
              exact
              path="/data-management/templates"
              render={() => (
                <TemplateComponent
                  ApplyClass={applyClass}
                  location={location}
                />
              )}
            />

            <Route
              exact
              path="/data-management/apis"
              render={() => <ApiSection />}
            />

            <Route
              exact
              path="/data-management/createRequest"
              render={ ()=> <CreateRequest/>}
            />

            <Route
              exact
              path="/data-management/manageTemplate"
              render={() => <TemplateView />}
            />

            <Route
              exact
              path="/data-management/uploadEvidence"
              render={() => <UploadEvidence />}
            />

            <Route
              exact
              path="/data-management/listEvidence"
              render={() => (
                <ListEvidence
                  showBackButton={true}
                  currentStep={3}
                />
              )}
            />
          </Switch>
        </div>
      </div>
    </div>,
    <FooterComponent key="footer"></FooterComponent>
  ];
};

export default DataManagement;
