/* eslint-disable react/prop-types */
import React, { useState } from "react";
import styles from "../ShowDataTable.module.css";

const TableHeader = ({
  header,
  index,
  handleDropdownChange,
  showSorting,
  showMatching,
  sortColumn,
  setSortColumn,
  handleAddClass,
  templateHeaders
}) => {

  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // Search term for filtering
  const [filteredOptions, setFilteredOptions] = useState(templateHeaders.slice(1)); // Filtered dropdown options

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredOptions(
      templateHeaders.slice(1).filter((option) =>
        option.toLowerCase().includes(value)
      )
    );
  };

  const handleDropdown = () => {
    setOpen(!open);
    setSearchTerm("");
    setFilteredOptions(templateHeaders.slice(1));
  }

  return (
    <th key={index} className={styles.tableHeaderCell}>
      <div className={`${handleAddClass(index)} ${styles.header}`}>
        <>
          <p className={styles.headerText}>{header}</p>
          {(!(header === "#" || header === "Actions" || header === "Title")) ? (showSorting ? (
            <select
              onClick={() => {
                if (sortColumn === index) setSortColumn(1);
                else setSortColumn(index);
              }}
              className={`${handleAddClass(index)} ${styles.select} ${showSorting ? (sortColumn !== index ? styles.sortAsc : styles.sortDsc) : styles.match
                }`}
            >
            </select>
          ) :
            (showMatching ?
              (
                <div className={styles.dropdown}>
                  <div
                    className={`${styles.match}`}
                    onClick={handleDropdown}
                  >
                  </div>
                  <ul className={`${styles.dropdownList} ${open ? "" : styles.hidden}`}>
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={handleSearch}
                      className={styles.searchInput}
                    />
                    <button
                      className={styles.closeButton}
                      onClick={handleDropdown}
                    >
                      &times;
                    </button>
                    {filteredOptions?.map((option, index) => (
                      <li
                        key={index}
                        className={styles.dropdownItem}
                        onClick={() => console.log(option)}
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                </div>
              ) :
              (
                <select
                  value={1}
                  onChange={(event) => handleDropdownChange(index, event)}
                  style={{ opacity: "0" }}
                  className={`${handleAddClass(index)} ${styles.select} ${showSorting ? (sortColumn !== index ? styles.sortAsc : styles.sortDsc) : styles.match
                    }`}
                >
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>

                </select>
              ))) : null}
        </>
      </div>
    </th>
  );
};

export default TableHeader;